import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";

// import HeroMobileEE from "./img/ee/hero_mobile.jpg";
// import HeroDesktopEE from "./img/ee/hero.jpg";
// import HeroMobileRU from "./img/ru/hero-mobile.jpg";
// import HeroDesktopRU from "./img/ru/hero.jpg";

// slider photos
import RubyBerrySlideEE from "./img/ee/RubyBerrySlideEE.jpg";
import RubyBerrySlideEEMobile from "./img/ee/RubyBerrySlideEEMobile.jpg";
import XFreezeSlideEE from "./img/ee/XFreezeSlideEE.png";
import XFreezeSlideEEMobile from "./img/ee/XFreezeSlideEEMobile.png";
import XFreezeSlideRU from "./img/ru/XFreezeSlideRU.png";
import XFreezeSlideRUMobile from "./img/ru/XFreezeSlideRUMobile.png";
import RegularHeroImg from "./img/ee/Regular_KV_desktop.jpg";
import RegularHeroImgMobile from "./img/ee/Regular_KV_mobile.jpg";
import RubyBerrySlideRU from "./img/ru/RubyBerryDesktopSliderRU.jpg";
import RubyBerrySlideMobile from "./img/ru/RubyBerrySlideRUMobile.jpg";
import RegularHeroImgRU from "./img/ru/Regular_KV_desktop_RU.jpg";
import RegularHeroImgRUMobile from "./img/ru/Regular_KV_mobile_RU.jpg";

import veloLogoSvg from "../assets/VeloPage/velo-logo.svg";
import veloHeroPack from "../assets/VeloPage/desktop/velo-hero.png";
import veloHeroPackMobile from "../assets/VeloPage/velo-hero.png";
import allProductsImage from "../assets/VeloPage/product-velo-0.png";
import productHeader from "./img/ee/product-velo-0.png";
import productHeaderMobile from "./img/ee/product-velo-0-mobile.png";
import productHeaderRU from "./img/ru/product-velo-0-RU.png";
import productHeaderMobileRU from "./img/ru/product-velo-0-mobile-RU.png";

import VeloMini from "./img/ee/velo-two.png";
import VeloSlim from "./img/ee/velo-four.png";
import VeloBlack from "./img/ee/velo-black.png";
import VeloCoolStorm from "./img/ee/velo-cool-storm.png";
import VeloCoolStormRU from "./img/ru/velo-cool-storm-ru.png";
import VeloMiniMobile from "./img/ee/velo-two-mobile.png";
import VeloSlimMobile from "./img/ee/velo-four-mobile.png";
import VeloBlackMobile from "./img/ee/velo-black-mobile.png";
import VeloCoolStormMobile from "./img/ee/velo-cool-storm-mobile.png";
import VeloCoolStormMobileRU from "./img/ru/velo-cool-storm-mobile-ru.png";

import VeloMiniRU from "./img/ru/velo-two.jpg";
import VeloSlimRU from "./img/ru/velo-four.png";
import VeloBlackRU from "./img/ru/velo-black.png";
import VeloMiniMobileRU from "./img/ru/velo-two-mobile.jpg";
import VeloSlimMobileRU from "./img/ru/velo-four-mobile.png";
import VeloBlackMobileRU from "./img/ru/velo-black-mobile.png";

import tutorialSvg1 from "../assets/VeloPage/tutorial1.png";
import tutorialSvg2 from "../assets/VeloPage/tutorial2.png";
import tutorialSvg3 from "../assets/VeloPage/tutorial-icon-3.svg";
import tutorialSvg4 from "../assets/VeloPage/tutorial4.png";

import findSectionImageEE from "./img/ee/footer-desktop.jpg";
import findSectionImageMobileEE from "./img/ee/footer-mobile.jpg";
import findSectionImageRU from "./img/ru/footer-desktop.jpg";
import findSectionImageMobileRU from "./img/ru/footer-mobile.jpg";

import EE from "../langs/ee.json";
import RU from "../langs/ru.json";

import productsInfo from "./img/products-info.png";

import Icon1 from "./img/icon01.png";
import Icon2 from "./img/icon02.png";
import Icon3 from "./img/icon03.png";
import Icon4 from "./img/icon04.png";

import setEvent from '../hook/setEvent';

import Slider from "../components/Slider/Slider";
// import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
// import { Navigation } from 'swiper';
// import 'swiper/css';
// import 'swiper/css/bundle';

const VeloPage = () => {
  // const swiper = useSwiper();
  const { lang } = useParams();
  const data = lang === "ee" ? EE : RU;

  const productHeaderDesktop = lang === "ee" ? productHeader : productHeaderRU
  const productHeaderMobiles = lang === "ee" ? productHeaderMobile : productHeaderMobileRU

  const slide1 = lang === "ee" ? RubyBerrySlideEE : RubyBerrySlideRU;
  const slide1mobile = lang === "ee" ? RubyBerrySlideEEMobile : RegularHeroImgRUMobile;
  const slide2 = lang === "ee" ? RegularHeroImg : RegularHeroImgRU;
  const slide2mobile = lang === "ee" ? RegularHeroImgMobile : RubyBerrySlideMobile;
  const slide3 = lang === "ee" ? XFreezeSlideEE : XFreezeSlideRU;
  const slide3mobile = lang === "ee" ? XFreezeSlideEEMobile : XFreezeSlideRUMobile;

  const VMini = lang === "ee" ? VeloMini : VeloMiniRU;
  const VMiniMobile = lang === "ee" ? VeloMiniMobile : VeloMiniRU;
  const VSlim = lang === "ee" ? VeloSlim : VeloSlimRU;
  const VSlimMobile = lang === "ee" ? VeloSlimMobile : VeloSlimRU;
  const VBlack = lang === "ee" ? VeloBlack : VeloBlackRU;
  const VBlackMobile = lang === "ee" ? VeloBlackMobile : VeloBlackMobileRU;
  const VBlackCool = lang === "ee" ?  VeloCoolStorm : VeloCoolStormRU;
  const VBlackCoolMobile = lang === "ee" ? VeloCoolStormMobile : VeloCoolStormMobileRU;

  const footerImage = lang === "ee" ? findSectionImageEE : findSectionImageRU;
  const footerImageMobile = lang === "ee" ? findSectionImageMobileEE : findSectionImageMobileRU;

  const assetsPath = process.env.REACT_APP_ENV === "prod" ? `/ee/ee` : process.env.REACT_APP_ENV === "test" ? '' : '.';

  useEffect(() => {
    setEvent({
      'UserId': null,
      'PageType': 'homepage',
      'SystemType': null,
      'Country': lang,
      'LoggedInStatus': 'logged out',
      'SiteSection': 'Home',
      'ServerSideTampstamp': Date.now()
    });
  }, []);

  return (
    <Layout footerType="black" langData={data}>
      <section className="velo-page">
        <Slider
          mobileImg1={slide1mobile}
          desktopImg1={slide1}
          mobileImg2={slide2mobile}
          desktopImg2={slide2}
          mobileImg3={slide3mobile}
          desktopImg3={slide3}
        />
        <div className="velo-intro" id="what-is-velo">
          <span className="velo-intro__logo test">
            <img src={veloLogoSvg} alt="Veleo" />
          </span>
          <div className="container--center">
            <div className="velo-intro__wrapper">
              <h1 className="velo-intro__title">{ data.experience.title }</h1>
              <span className="velo-intro__image">
                <img
                  className="velo-intro__image--desktop"
                  src={veloHeroPack}
                  alt="velo"
                />
                <img
                  className="velo-intro__image--mobile"
                  src={veloHeroPackMobile}
                  alt="velo"
                />
              </span>
              <div className="velo-intro__content">
                <p className="velo-intro__desc">
                { data.experience.subtitle }
                </p>
              </div>
              <h2>{ data.experience.title_2 }</h2>
            </div>
            <div className="icons">
              <div className="icon">
                <img src={Icon1} />
                <div className="name">{ data.experience.icons[0] }</div>
              </div>
              <div className="icon">
                <img src={Icon2} />
                <div className="name">{ data.experience.icons[1] }</div>
              </div>
              <div className="icon">
                <img src={Icon3} />
                <div className="name">{ data.experience.icons[2] }</div>
              </div>
              <div className="icon">
                <img src={Icon4} />
                <div className="name">{ data.experience.icons[3] }</div>
              </div>
            </div>
            <div className="points">
              {data.experience.points.map((item, key) => {
                return (
                  <div className="point" key={'points' + key}>
                    <span className="point-id">{item.id}</span>
                    <p><strong>{item.title}</strong><br />{item.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="velo-products" id="velo-products">
          <div className="container--center">
            <div className="velo-products__image">
              <div className="velo-products__image--photo">
                <picture>
                  <source
                    media="(max-width: 719px)"
                    srcSet={productHeaderMobiles}
                  />
                  <source media="(min-width: 720px)" srcSet={productHeaderDesktop} />
                  <img
                    className="velo-product__image"
                    src={productHeaderDesktop}
                    alt="Velo All packs"
                  />
                </picture>
              </div>
            </div>
          </div>

          <div className="container--center">
            <h2 className="velo-products__title">{data.product_sets.title}</h2>
            <ul className="velo-product__list">
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category--nospace blue-text">{ data.product_sets.subtitle }</h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VMiniMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VMini }/>
                      <img className="velo-product__image" src={ VMini } alt="Velo Mini"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.mini.text }}>
                  </p>
                  <p className="velo-product__desc">{ data.product_sets.mini.nicotine }</p>
                  <p className="velo-product__desc">{ data.product_sets.mini.subtext }</p>
                </div>
              </li>
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category blue-text">{ data.product_sets.slim.title }</h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VSlimMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VSlim }/>
                      <img className="velo-product__image" src={ VSlim } alt="Velo Slim"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.slim.text }}>
                  </p>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.slim?.text2 }}>
                  </p>
                  <p className="velo-product__desc">
                    {data.product_sets.slim.nicotine}
                  </p>
                  <p className="velo-product__desc">
                    {data.product_sets.slim.subtext}
                  </p>
                  <br />
                </div>
              </li>
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category blue-text">{ data.product_sets.coolstorm.title }</h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VBlackCoolMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VBlackCool }/>
                      <img className="velo-product__image" src={ VBlackCool } alt="Velo Cool Storm"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.coolstorm.text }}>
                  </p>
                  <p className="velo-product__desc">{ data.product_sets.coolstorm.nicotine }</p>
                  <p className="velo-product__desc">{ data.product_sets.coolstorm.subtext }</p>
                </div>
              </li>
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category blue-text" dangerouslySetInnerHTML={{__html: data.product_sets.black.title }}></h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VBlackMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VBlack }/>
                      <img className="velo-product__image" src={ VBlack } alt="Velo Black"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.black.text }}>
                  </p>
                  <p className="velo-product__desc">{ data.product_sets.black.nicotine }</p>
                  <p className="velo-product__desc">{ data.product_sets.black.subtext }</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="product-cards-section">
          <h2 className="blue-text">{ data.products.title }</h2>
          <ul className="product-cards">
            { data.products.black.map((item, key) => {
              return (
                <li key={"mini"+key} className="product-card">
                  <img src={`${assetsPath}/assets/product/${item.id}.jpg`} alt={ item.name } />
                  <h3 className="product-title">{ item.name }</h3>
                  <p>{ item.nicotine }</p>
                  <div className={"strength "+item.color}>
                    { item.strength.map((sitem, skey)=>{
                      return <div key={item.id+skey} className={sitem === 1 ? 'full' : "empty"}></div>
                    })}
                  </div>
                  <p className="description">{ item.desc }</p>
                  <p className="info">{ item.info }</p>
                </li>
              )
            })}
          </ul>
          <ul className="product-cards">
            { data.products.slim.map((item, key) => {
              return (
                <li key={"mini"+key} className="product-card">
                  <img src={`${assetsPath}/assets/product/${item.id}.jpg`} alt={ item.name } />
                  <h3 className="product-title">{ item.name }</h3>
                  <p>{ item.nicotine }</p>
                  <div className={"strength "+item.color}>
                    { item.strength.map((sitem, skey)=>{
                      return <div key={item.id+skey} className={sitem === 1 ? 'full' : "empty"}></div>
                    })}
                  </div>
                  <p className="description">{ item.desc }</p>
                  <p className="info">{ item.info }</p>
                </li>
              )
            })}
          </ul>
          <ul className="product-cards">
            {data.products.slim2?.map((item, key) => {
              return (
                <li key={"mini" + key} className="product-card">
                  <img src={`${assetsPath}/assets/product/${item.id}.jpg`} />
                  <h3 className="product-title">{item.name}</h3>
                  <p>{item.nicotine}</p>
                  <div className={"strength " + item.color}>
                    {item.strength.map((sitem, skey) => {
                      return (
                        <div
                          key={item.id + skey}
                          className={sitem === 1 ? "full" : "empty"}
                        ></div>
                      );
                    })}
                  </div>
                  <p className="description">{item.desc}</p>
                  <p className="info">{item.info}</p>
                </li>
              );
            })}
          </ul>
          <ul className="product-cards">
            { data.products.mini.map((item, key) => {
              return (
                <li key={"mini"+key} className="product-card good-for-beginners">
                  <img src={`${assetsPath}/assets/product/${item.id}.jpg`} alt={ item.name } />
                  <h3 className="product-title">{ item.name }</h3>
                  <p>{ item.nicotine }</p>
                  <div className={"strength "+item.color}>
                    { item.strength.map((sitem, skey)=>{
                      return <div key={item.id+skey} className={sitem === 1 ? 'full' : "empty"}></div>
                    })}
                  </div>
                  <p className="description">{ item.desc }</p>
                  <p className="info">{ item.info }</p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="velo-how" id="how-to-use">
          <div className="container--center">
            <h2 className="velo-how__title">
              { data.how_to.title }<span>{ data.how_to.subtitle }</span>
            </h2>
            <ul className="velo-how-tutorial">
              <li className="velo-how-tutorial__step">
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">1.</span>
                  <div className="icon">
                    <img src={tutorialSvg1} alt="tutorial step" />
                  </div>
                </span>
                <p className="velo-how-tutorial__desc">
                  { data.how_to.one }
                </p>
              </li>
              <li className="velo-how-tutorial__step">
                <p className="velo-how-tutorial__desc">
                { data.how_to.two }
                </p>
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">2.</span>
                  <div className="icon">
                    <img src={tutorialSvg2} alt="tutorial step" />
                  </div>
                </span>
              </li>
              <li className="velo-how-tutorial__step">
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">3.</span>
                  <div className="icon">
                    <img className="timer" src={tutorialSvg3} alt="tutorial step" />
                  </div>
                </span>
                <p className="velo-how-tutorial__desc">
                  { data.how_to.three }
                </p>
              </li>
              <li className="velo-how-tutorial__step">
                <p className="velo-how-tutorial__desc">
                  { data.how_to.four }
                </p>
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">4.</span>
                  <div className="icon">
                    <img src={tutorialSvg4} alt="tutorial step" />
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="velo-find">
          <div className="velo-find__image">
            <img
              className="velo-find__image--desktop"
              src={footerImage}
              alt=" "
            />
            <img
              className="velo-find__image--mobile"
              src={footerImageMobile}
              alt=" "
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default VeloPage;
