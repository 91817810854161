const stores = {
  "type": "FeatureCollection",
  "features": [
    { "type": "Feature", "id": 1, "properties": { "store_name": "Feenoks, Anne", "address": "Anne 24, 50604", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.74767, 58.3805] } },
    { "type": "Feature", "id": 2, "properties": { "store_name": "Feenoks, Kuperjanovi ", "address": "Kuperjanovi 20, 50709", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.71197, 58.37628] } },
    { "type": "Feature", "id": 3, "properties": { "store_name": "Feenoks, Kaunase ", "address": "Kaunase pst 21a, 50406", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.76758, 58.37376] } },
    { "type": "Feature", "id": 4, "properties": { "store_name": "Tallinna Kaubamaja Toidumaailm", "address": "Viru väljak 4, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.75592, 59.43667] } },
    { "type": "Feature", "id": 5, "properties": { "store_name": "Tartu Kaubamaja toidumaailm", "address": "Riia 1, Tartu", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7274, 58.37804] } },
    { "type": "Feature", "id": 6, "properties": { "store_name": "Ahtme Grossi Toidukaubad", "address": "Estonia pst 30A", "postcode": "", "city": "Ahtme" }, "geometry": { "type": "Point", "coordinates": [27.42627, 59.32985] } },
    { "type": "Feature", "id": 7, "properties": { "store_name": "Aseri Grossi Toidukaubad", "address": "Tehase 23", "postcode": "", "city": "Aseri" }, "geometry": { "type": "Point", "coordinates": [26.86681, 59.44974] } },
    { "type": "Feature", "id": 8, "properties": { "store_name": "Ehitajate tee Grossi Toidukaubad", "address": "Ehitajate tee 41", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.66857, 59.4019] } },
    { "type": "Feature", "id": 9, "properties": { "store_name": "Elektra Grossi Toidukaubad", "address": "Tobia küla", "postcode": "", "city": "Tobia k" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 10, "properties": { "store_name": "Endla Grossi Toidukaubad", "address": "Endla 53", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.71567, 59.42763] } },
    { "type": "Feature", "id": 11, "properties": { "store_name": "Eve Grossi Toidukaubad", "address": "Sõpruse 5", "postcode": "", "city": "Vinni" }, "geometry": { "type": "Point", "coordinates": [26.42803, 59.29112] } },
    { "type": "Feature", "id": 12, "properties": { "store_name": "Gea Grossi Toidukaubad", "address": "Võsu mnt 5", "postcode": "", "city": "Haljala" }, "geometry": { "type": "Point", "coordinates": [26.2664, 59.43387] } },
    { "type": "Feature", "id": 13, "properties": { "store_name": "Iidla Grossi Toidukaubad", "address": "Maleva 23", "postcode": "", "city": "Ahtme" }, "geometry": { "type": "Point", "coordinates": [27.4205, 59.32754] } },
    { "type": "Feature", "id": 14, "properties": { "store_name": "Iisaku Grossi Toidukaubad", "address": "Tartu mnt 55", "postcode": "", "city": "Iisaku" }, "geometry": { "type": "Point", "coordinates": [27.31457, 59.09511] } },
    { "type": "Feature", "id": 15, "properties": { "store_name": "Joogid Grossi Toidukaubad", "address": "Vilde 6a", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.36425, 59.34616] } },
    { "type": "Feature", "id": 16, "properties": { "store_name": "Jõgeva Grossi Toidukaubad", "address": "Tähe 10a", "postcode": "", "city": "Jõgeva" }, "geometry": { "type": "Point", "coordinates": [26.39146, 58.74966] } },
    { "type": "Feature", "id": 17, "properties": { "store_name": "Jõgeva Pargi Grossi Toidukaubad", "address": "Pargi 1", "postcode": "", "city": "Jõgeva" }, "geometry": { "type": "Point", "coordinates": [26.39924, 58.74585] } },
    { "type": "Feature", "id": 18, "properties": { "store_name": "Jõhvi Grossi Toidukaubad", "address": "Tartu mnt 15a", "postcode": "", "city": "Jõhvi" }, "geometry": { "type": "Point", "coordinates": [27.41157, 59.35355] } },
    { "type": "Feature", "id": 19, "properties": { "store_name": "Järva-Jaani Grossi Toidukaubad", "address": "Lai 23", "postcode": "", "city": "Järva-Jaani" }, "geometry": { "type": "Point", "coordinates": [25.88397, 59.03852] } },
    { "type": "Feature", "id": 20, "properties": { "store_name": "Järvakandi Grossi Toidukaubad", "address": "Turu tn 1", "postcode": "", "city": "Järvakandi" }, "geometry": { "type": "Point", "coordinates": [24.81759, 58.77449] } },
    { "type": "Feature", "id": 21, "properties": { "store_name": "Järveotsa Grossi Toidukaubad", "address": "Järveotsa tee 35b", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.64064, 59.4075] } },
    { "type": "Feature", "id": 22, "properties": { "store_name": "Jüri Grossi Toidukaubad", "address": "Aruküla tee 7", "postcode": "", "city": "Jüri" }, "geometry": { "type": "Point", "coordinates": [24.89302, 59.35422] } },
    { "type": "Feature", "id": 23, "properties": { "store_name": "Kadrina Grossi Toidukaubad", "address": "Viru 9", "postcode": "", "city": "Kadrina" }, "geometry": { "type": "Point", "coordinates": [26.13076, 59.3414] } },
    { "type": "Feature", "id": 24, "properties": { "store_name": "Kalevi Grossi Toidukaubad", "address": "Kalevi 27", "postcode": "", "city": "K-Järve" }, "geometry": { "type": "Point", "coordinates": [27.28307, 59.40555] } },
    { "type": "Feature", "id": 25, "properties": { "store_name": "Kari Grossi Toidukaubad", "address": "Kari 3", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.69785, 59.44786] } },
    { "type": "Feature", "id": 26, "properties": { "store_name": "Kehra Grossi Toidukaubad", "address": "Kose mnt 7", "postcode": "", "city": "Kehra" }, "geometry": { "type": "Point", "coordinates": [25.33298, 59.33484] } },
    { "type": "Feature", "id": 27, "properties": { "store_name": "Keila Grossi Toidukaubad", "address": "Piiri 7", "postcode": "", "city": "Keila" }, "geometry": { "type": "Point", "coordinates": [24.41905, 59.31342] } },
    { "type": "Feature", "id": 28, "properties": { "store_name": "Kevad Grossi Toidukaubad", "address": "Keskpuiestee 33", "postcode": "", "city": "Kiviõli" }, "geometry": { "type": "Point", "coordinates": [26.9753, 59.35653] } },
    { "type": "Feature", "id": 29, "properties": { "store_name": "Kivila Grossi Toidukaubad", "address": "Kivila 26", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.87281, 59.44375] } },
    { "type": "Feature", "id": 30, "properties": { "store_name": "Koeru Grossi Toidukaubad", "address": "Paide tee 2", "postcode": "", "city": "Koeru" }, "geometry": { "type": "Point", "coordinates": [24.02828, 59.96435] } },
    { "type": "Feature", "id": 31, "properties": { "store_name": "Kohila Grossi Toidukaubad", "address": "Viljandi mnt 3a", "postcode": "", "city": "Kohila" }, "geometry": { "type": "Point", "coordinates": [24.76037, 59.16751] } },
    { "type": "Feature", "id": 32, "properties": { "store_name": "Kose Grossi Toidukaubad", "address": "Kodu tn 2", "postcode": "", "city": "Kose" }, "geometry": { "type": "Point", "coordinates": [25.16454, 59.18429] } },
    { "type": "Feature", "id": 33, "properties": { "store_name": "Kose-Uuemõisa Grossi Toidukaubad", "address": "Kesk tn 1", "postcode": "", "city": "Kose-Uuemõisa" }, "geometry": { "type": "Point", "coordinates": [25.10419, 59.20497] } },
    { "type": "Feature", "id": 34, "properties": { "store_name": "Kotka Grossi Toidukaubad", "address": "Pinna 21", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.82385, 59.43911] } },
    { "type": "Feature", "id": 35, "properties": { "store_name": "Kunda Grossi Toidukaubad", "address": "Kasemäe 12", "postcode": "", "city": "Kunda" }, "geometry": { "type": "Point", "coordinates": [26.51787, 59.50074] } },
    { "type": "Feature", "id": 36, "properties": { "store_name": "Kuusalu Grossi Toidukaubad", "address": "Mäe 1", "postcode": "", "city": "Kuusalu" }, "geometry": { "type": "Point", "coordinates": [25.44415, 59.4468] } },
    { "type": "Feature", "id": 37, "properties": { "store_name": "Laagna Grossi Toidukaubad", "address": "Vikerlase 23", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.84587, 59.43956] } },
    { "type": "Feature", "id": 38, "properties": { "store_name": "Lastekodu Grossi Toidukaubad", "address": "Lastekodu 14", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.76809, 59.43048] } },
    { "type": "Feature", "id": 39, "properties": { "store_name": "Loksa Grossi Toidukaubad", "address": "Rohuaia 6", "postcode": "", "city": "Loksa" }, "geometry": { "type": "Point", "coordinates": [25.72798, 59.57936] } },
    { "type": "Feature", "id": 40, "properties": { "store_name": "Loo Grossi Toidukaubad", "address": "Saha tee 9", "postcode": "", "city": "Loo" }, "geometry": { "type": "Point", "coordinates": [24.94732, 59.43463] } },
    { "type": "Feature", "id": 41, "properties": { "store_name": "Ly Grossi Toidukaubad", "address": "Vilde 6 ", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.36463, 59.34626] } },
    { "type": "Feature", "id": 42, "properties": { "store_name": "Maardu Grossi Toidukaubad", "address": "Keemikute 41", "postcode": "", "city": "Maardu" }, "geometry": { "type": "Point", "coordinates": [25.01692, 59.48432] } },
    { "type": "Feature", "id": 43, "properties": { "store_name": "Majaka Grossi Toidukaubad", "address": "Majaka 28a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.79105, 59.42839] } },
    { "type": "Feature", "id": 44, "properties": { "store_name": "Marja Grossi Toidukaubad", "address": "Mustamäe tee 41", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.69974, 59.4222] } },
    { "type": "Feature", "id": 45, "properties": { "store_name": "Mustvee Grossi Toidukaubad", "address": "Tähe tn 9", "postcode": "", "city": "Mustvee" }, "geometry": { "type": "Point", "coordinates": [26.94327, 58.84971] } },
    { "type": "Feature", "id": 46, "properties": { "store_name": "Narva Megamarket Grossi Toidukaubad", "address": "Rakvere 71", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.18426, 59.386] } },
    { "type": "Feature", "id": 47, "properties": { "store_name": "Narva Pähklimäe 2a Grossi Toidukaubad", "address": "Pähklimäe tn 2a", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.1729, 59.38735] } },
    { "type": "Feature", "id": 48, "properties": { "store_name": "Narva Pähklimäe 6a Grossi Toidukaubad", "address": "Pähklimäe tn 6a", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.16861, 59.38721] } },
    { "type": "Feature", "id": 49, "properties": { "store_name": "Narva Tallinna 47 Grossi Toidukaubad", "address": "Tallinna 47", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.1728, 59.38163] } },
    { "type": "Feature", "id": 50, "properties": { "store_name": "Narva Tallinna 52 Grossi Toidukaubad", "address": "Tallinna 52", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.16509, 59.38247] } },
    { "type": "Feature", "id": 51, "properties": { "store_name": "Oru Grossi Toidukaubad", "address": "Virmalise 2", "postcode": "", "city": "K-Järve" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 52, "properties": { "store_name": "Outokumpu Grossi Toidukaubad", "address": "Outokumpu 13a", "postcode": "", "city": "K-Järve" }, "geometry": { "type": "Point", "coordinates": [27.29475, 59.39714] } },
    { "type": "Feature", "id": 53, "properties": { "store_name": "Paasiku Grossi Toidukaubad", "address": "Paasiku 2a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.84496, 59.44855] } },
    { "type": "Feature", "id": 54, "properties": { "store_name": "Paide Grossi Toidukaubad", "address": "Pikk 25", "postcode": "", "city": "Paide" }, "geometry": { "type": "Point", "coordinates": [25.56964, 58.88433] } },
    { "type": "Feature", "id": 55, "properties": { "store_name": "Paldiski Grossi Toidukaubad ", "address": "Rae 26", "postcode": "", "city": "Paldiski" }, "geometry": { "type": "Point", "coordinates": [24.05973, 59.34978] } },
    { "type": "Feature", "id": 56, "properties": { "store_name": "Pille-Riin Grossi Toidukaubad", "address": "Tehnika 24", "postcode": "", "city": "Tamsalu" }, "geometry": { "type": "Point", "coordinates": [26.11228, 59.16238] } },
    { "type": "Feature", "id": 57, "properties": { "store_name": "Põltsamaa Grossi Toidukaubad", "address": "Tartu mnt 1b", "postcode": "", "city": "Põltsamaa" }, "geometry": { "type": "Point", "coordinates": [25.98268, 58.65258] } },
    { "type": "Feature", "id": 58, "properties": { "store_name": "Põlva Grossi Toidukaubad", "address": "Kesk tn 41", "postcode": "", "city": "Põlva" }, "geometry": { "type": "Point", "coordinates": [27.04404, 58.05219] } },
    { "type": "Feature", "id": 59, "properties": { "store_name": "Pärnu-Jaagupi Grossi Toidukaubad", "address": "Uus tn 20a", "postcode": "", "city": "Pärnu-Jaagupi" }, "geometry": { "type": "Point", "coordinates": [24.50486, 58.60936] } },
    { "type": "Feature", "id": 60, "properties": { "store_name": "Raja Grossi Toidukaubad", "address": "Ilu pst 2", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.34219, 59.35189] } },
    { "type": "Feature", "id": 61, "properties": { "store_name": "Rakke Grossi Toidukaubad", "address": "Faehlmanni 38", "postcode": "", "city": "Rakke" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 62, "properties": { "store_name": "Randvere Grossi Toidukaubad", "address": "Randvere tee 115", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8788, 59.49151] } },
    { "type": "Feature", "id": 63, "properties": { "store_name": "Saue Grossi Toidukaubad", "address": "Pärnasalu põik 1", "postcode": "", "city": "Saue" }, "geometry": { "type": "Point", "coordinates": [24.55729, 59.32269] } },
    { "type": "Feature", "id": 64, "properties": { "store_name": "Tammsaare tee Grossi Toidukaubad", "address": "Tammsaare tee 93", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.69133, 59.40613] } },
    { "type": "Feature", "id": 65, "properties": { "store_name": "Tapa Kaubanduskeskus Grossi Toidukaubad", "address": "Jaama 1", "postcode": "", "city": "Tapa" }, "geometry": { "type": "Point", "coordinates": [25.96312, 59.26467] } },
    { "type": "Feature", "id": 66, "properties": { "store_name": "Tartu Grossi Toidukaubad", "address": "Soola 7", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7356, 58.37837] } },
    { "type": "Feature", "id": 67, "properties": { "store_name": "Tartu Raadi Grossi Toidukaubad", "address": "Keskuse tee 2", "postcode": "", "city": "Tila küla, Tartu vald" }, "geometry": { "type": "Point", "coordinates": [26.74259, 58.40855] } },
    { "type": "Feature", "id": 68, "properties": { "store_name": "Turu Kaubamaja Grossi Toidukaubad", "address": "Laada 16", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.36378, 59.34778] } },
    { "type": "Feature", "id": 69, "properties": { "store_name": "Türi Grossi Toidukaubad", "address": "Viljandi 13a", "postcode": "", "city": "Türi" }, "geometry": { "type": "Point", "coordinates": [25.42952, 58.80336] } },
    { "type": "Feature", "id": 70, "properties": { "store_name": "Viimsi Grossi Toidukaubad", "address": "Kaluri tee 3", "postcode": "", "city": "Viimsi" }, "geometry": { "type": "Point", "coordinates": [24.82725, 59.51003] } },
    { "type": "Feature", "id": 71, "properties": { "store_name": "Viljandi Grossi Toidukaubad", "address": "Jakobsoni 2", "postcode": "", "city": "Viljandi" }, "geometry": { "type": "Point", "coordinates": [25.59242, 58.36748] } },
    { "type": "Feature", "id": 72, "properties": { "store_name": "Voka Grossi Toidukaubad", "address": "Narva mnt 5", "postcode": "", "city": "Voka" }, "geometry": { "type": "Point", "coordinates": [27.58145, 59.40434] } },
    { "type": "Feature", "id": 73, "properties": { "store_name": "Võru Grossi Toidukaubad", "address": "Niidu tn 8", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [27.0062, 57.83752] } },
    { "type": "Feature", "id": 74, "properties": { "store_name": "Väike-Ameerika Grossi Toidukaubad", "address": "Väike-Ameerika 6a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.73555, 59.42621] } },
    { "type": "Feature", "id": 75, "properties": { "store_name": "Väike-Maarja Grossi Toidukaubad", "address": "Pikk 9", "postcode": "", "city": "V-Maarja" }, "geometry": { "type": "Point", "coordinates": [26.25224, 59.13017] } },
    { "type": "Feature", "id": 76, "properties": { "store_name": "Vändra Grossi Toidukaubad", "address": "Pärnu-Paide mnt 26", "postcode": "", "city": "Vändra" }, "geometry": { "type": "Point", "coordinates": [25.0429, 58.65652] } },
    { "type": "Feature", "id": 77, "properties": { "store_name": "Ümera Grossi Toidukaubad", "address": "Ümera 13a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.89213, 59.44428] } },
    { "type": "Feature", "id": 78, "properties": { "store_name": "Maxima XX Õismäe ", "address": "Õismäe tee 46", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.64731, 59.41166] } },
    { "type": "Feature", "id": 79, "properties": { "store_name": "Maxima XX Vilde", "address": "Vilde tee 75/77", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.68515, 59.40284] } },
    { "type": "Feature", "id": 80, "properties": { "store_name": "Maxima XXX Linnamäe", "address": "Linnamäe tee 57", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.88024, 59.45008] } },
    { "type": "Feature", "id": 81, "properties": { "store_name": "Maxima XX Tammsaare", "address": "Tammsaare tee 133", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.67549, 59.40888] } },
    { "type": "Feature", "id": 82, "properties": { "store_name": "Maxima XXX Ehitajate", "address": "Ehitajate tee 148", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.64981, 59.42126] } },
    { "type": "Feature", "id": 83, "properties": { "store_name": "Maxima XX Sõbra", "address": "Võru 55f   (Sõbra keskus)", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.71949, 58.36534] } },
    { "type": "Feature", "id": 84, "properties": { "store_name": "Maxima XX Maardu", "address": "Keemikute tn 2", "postcode": "", "city": "Maardu" }, "geometry": { "type": "Point", "coordinates": [25.01927, 59.47351] } },
    { "type": "Feature", "id": 85, "properties": { "store_name": "Maxima XX Kreenholmi", "address": "Kreenholmi 52/54", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.18754, 59.36455] } },
    { "type": "Feature", "id": 86, "properties": { "store_name": "Maxima XX Kuressaare", "address": "Tallinna 64", "postcode": "", "city": "Kuressaare" }, "geometry": { "type": "Point", "coordinates": [22.50513, 58.26067] } },
    { "type": "Feature", "id": 87, "properties": { "store_name": "Maxima XX Turu", "address": "Turu 14", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.73606, 58.37479] } },
    { "type": "Feature", "id": 88, "properties": { "store_name": "Maxima XX Kalda", "address": "Kalda tee 15", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.76269, 58.37132] } },
    { "type": "Feature", "id": 89, "properties": { "store_name": "Maxima XX Paepargi", "address": "Paepargi 57", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8112, 59.4353] } },
    { "type": "Feature", "id": 90, "properties": { "store_name": "Maxima XX Pavlovi", "address": "Pavlovi 1a", "postcode": "", "city": "Sillamäe" }, "geometry": { "type": "Point", "coordinates": [27.77439, 59.39096] } },
    { "type": "Feature", "id": 91, "properties": { "store_name": "Maxima XX Smuuli", "address": "Smuuli 9", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.82273, 59.44385] } },
    { "type": "Feature", "id": 92, "properties": { "store_name": "Maxima XX Tuulemaa", "address": "Madala 5a/Tuulemaa 20", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.693, 59.44721] } },
    { "type": "Feature", "id": 93, "properties": { "store_name": "Maxima XXX Riia", "address": "Riia mnt 131", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.53705, 58.37473] } },
    { "type": "Feature", "id": 94, "properties": { "store_name": "Maxima XX Puru", "address": "Puru 77", "postcode": "", "city": "Kohtla-Järve, Ahtme linnaosa" }, "geometry": { "type": "Point", "coordinates": [27.42012, 59.33796] } },
    { "type": "Feature", "id": 95, "properties": { "store_name": "Maxima XX Kooli", "address": "Kooli 2", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [27.00479, 57.84797] } },
    { "type": "Feature", "id": 96, "properties": { "store_name": "Maxima XX Valga", "address": "Jaama 2b", "postcode": "", "city": "Valga" }, "geometry": { "type": "Point", "coordinates": [26.04449, 57.77432] } },
    { "type": "Feature", "id": 97, "properties": { "store_name": "Maxima XX Jõhvi", "address": "Rakvere tn. 29", "postcode": "", "city": "Jõhvi" }, "geometry": { "type": "Point", "coordinates": [27.40123, 59.36235] } },
    { "type": "Feature", "id": 98, "properties": { "store_name": "Maxima XX Järveküla", "address": "Järveküla tee 50", "postcode": "", "city": "Kohtla- Järve" }, "geometry": { "type": "Point", "coordinates": [27.28598, 59.39765] } },
    { "type": "Feature", "id": 99, "properties": { "store_name": "Maxima XX Tondiraba", "address": "Tondiraba 7", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.85455, 59.45057] } },
    { "type": "Feature", "id": 100, "properties": { "store_name": "Maxima XX Narva mnt", "address": "Narva mnt 112", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.73015, 58.39182] } },
    { "type": "Feature", "id": 101, "properties": { "store_name": "Olerex, Tallinn Ahtri", "address": "Ahtri 6B", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.75944, 59.43987] } },
    { "type": "Feature", "id": 102, "properties": { "store_name": "Olerex,Alatskivi", "address": "Tartu mnt 5", "postcode": "", "city": "Alatskivi alevik" }, "geometry": { "type": "Point", "coordinates": [27.13257, 58.5993] } },
    { "type": "Feature", "id": 103, "properties": { "store_name": "Olerex, Antsla", "address": "F. R. Kreutzwaldi 11", "postcode": "", "city": "Antsla" }, "geometry": { "type": "Point", "coordinates": [26.52398, 57.83028] } },
    { "type": "Feature", "id": 104, "properties": { "store_name": "Olerex, Aovere", "address": "Torinurga", "postcode": "", "city": "Aovere küla" }, "geometry": { "type": "Point", "coordinates": [26.80864, 58.44678] } },
    { "type": "Feature", "id": 105, "properties": { "store_name": "Olerex, Elva", "address": "Valga mnt 3", "postcode": "", "city": "Elva" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 106, "properties": { "store_name": "Olerex, Iisaku", "address": "Tartu mnt 67", "postcode": "", "city": "Iisaku alevik" }, "geometry": { "type": "Point", "coordinates": [27.31678, 59.08251] } },
    { "type": "Feature", "id": 107, "properties": { "store_name": "Olerex, Tartu Jaama ATM/pood", "address": "Jaama 82", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.75548, 58.38107] } },
    { "type": "Feature", "id": 108, "properties": { "store_name": "Olerex, Jõelähtme", "address": "Kivisilla tee 1", "postcode": "", "city": "Jõelähtme küla" }, "geometry": { "type": "Point", "coordinates": [25.13745, 59.44094] } },
    { "type": "Feature", "id": 109, "properties": { "store_name": "Olerex, Järva-Jaani", "address": "Koeru tee 21", "postcode": "", "city": "Järva-Jaani alev" }, "geometry": { "type": "Point", "coordinates": [25.89065, 59.03309] } },
    { "type": "Feature", "id": 110, "properties": { "store_name": "Olerex, Kiviõli", "address": "Vabaduse pst 25", "postcode": "", "city": "Kiviõli" }, "geometry": { "type": "Point", "coordinates": [26.96805, 59.36481] } },
    { "type": "Feature", "id": 111, "properties": { "store_name": "Olerex, Kuressaare", "address": "Tallinna tn 84", "postcode": "", "city": "Kuressaare" }, "geometry": { "type": "Point", "coordinates": [22.51355, 58.26478] } },
    { "type": "Feature", "id": 112, "properties": { "store_name": "Olerex, Käina", "address": "Mäe 2", "postcode": "", "city": "Käina alevik" }, "geometry": { "type": "Point", "coordinates": [22.77378, 58.82924] } },
    { "type": "Feature", "id": 113, "properties": { "store_name": "Olerex, Kärdla", "address": "Kõrgessaare mnt 45", "postcode": "", "city": "Kärdla" }, "geometry": { "type": "Point", "coordinates": [22.72184, 58.9947] } },
    { "type": "Feature", "id": 114, "properties": { "store_name": "Olerex, Lähte", "address": "Kase 2", "postcode": "", "city": "Lähte alevik" }, "geometry": { "type": "Point", "coordinates": [26.68183, 58.49089] } },
    { "type": "Feature", "id": 115, "properties": { "store_name": "Olerex, Maardu", "address": "Keemikute 1", "postcode": "", "city": "Maardu" }, "geometry": { "type": "Point", "coordinates": [25.01645, 59.47072] } },
    { "type": "Feature", "id": 116, "properties": { "store_name": "Olerex, Paide Tallinna", "address": "Tallinna mnt 59", "postcode": "", "city": "Paide" }, "geometry": { "type": "Point", "coordinates": [25.57127, 58.8948] } },
    { "type": "Feature", "id": 117, "properties": { "store_name": "Olerex, Põltsamaa", "address": "Puhu rist", "postcode": "", "city": "Mällikvere küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 118, "properties": { "store_name": "Olerex, Pärnu Tallinna", "address": "Tallinna mnt 95", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.49424, 58.40982] } },
    { "type": "Feature", "id": 119, "properties": { "store_name": "Olerex, Rakvere Rägavere 50", "address": "Rägavere tee 50", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.3862, 59.33794] } },
    { "type": "Feature", "id": 120, "properties": { "store_name": "Olerex, Tartu Räni", "address": "Aardla 107", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.68595, 58.35414] } },
    { "type": "Feature", "id": 121, "properties": { "store_name": "Olerex, Saue", "address": "Tule 51", "postcode": "", "city": "Saue" }, "geometry": { "type": "Point", "coordinates": [24.56258, 59.31293] } },
    { "type": "Feature", "id": 122, "properties": { "store_name": "Olerex, Sillamäe", "address": "Tallinna mnt 9", "postcode": "", "city": "Sillamäe" }, "geometry": { "type": "Point", "coordinates": [27.77343, 59.39118] } },
    { "type": "Feature", "id": 123, "properties": { "store_name": "Olerex, Sindi", "address": "Ringi 5c", "postcode": "", "city": "Sindi" }, "geometry": { "type": "Point", "coordinates": [24.66037, 58.40487] } },
    { "type": "Feature", "id": 124, "properties": { "store_name": "Olerex, Soe", "address": "Soe küla", "postcode": "", "city": "Soe küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 125, "properties": { "store_name": "Olerex, Suure-Jaani ATM/pood", "address": "Jaama 31", "postcode": "", "city": "Suure-Jaani" }, "geometry": { "type": "Point", "coordinates": [25.48451, 58.53654] } },
    { "type": "Feature", "id": 126, "properties": { "store_name": "Olerex, Tallinn Laagna", "address": "Laagna tee 13", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.80923, 59.43639] } },
    { "type": "Feature", "id": 127, "properties": { "store_name": "Olerex, Tõrva", "address": "Valga 72", "postcode": "", "city": "Tõrva" }, "geometry": { "type": "Point", "coordinates": [25.93993, 57.99093] } },
    { "type": "Feature", "id": 128, "properties": { "store_name": "Olerex, Viljandi", "address": "Metsküla tee 9", "postcode": "", "city": "Viljandi" }, "geometry": { "type": "Point", "coordinates": [25.56227, 58.36497] } },
    { "type": "Feature", "id": 129, "properties": { "store_name": "Olerex, Võru Tallinna", "address": "Tallinna mnt 38", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [26.9766, 57.85909] } },
    { "type": "Feature", "id": 130, "properties": { "store_name": "Olerex, Pärnu Mai", "address": "38838", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 131, "properties": { "store_name": "Olerex, Aseri", "address": "Aia 2", "postcode": "", "city": "Koogu küla" }, "geometry": { "type": "Point", "coordinates": [26.80824, 59.43896] } },
    { "type": "Feature", "id": 132, "properties": { "store_name": "Olerex, Tartu Veeriku ATM/pood", "address": "Veeriku 1", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.6953, 58.37574] } },
    { "type": "Feature", "id": 133, "properties": { "store_name": "Olerex, Jõgeva", "address": "Estakaadi 1", "postcode": "", "city": "Jõgeva" }, "geometry": { "type": "Point", "coordinates": [26.40055, 58.74532] } },
    { "type": "Feature", "id": 134, "properties": { "store_name": "Olerex, Tallinn Laki", "address": "Laki 29", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6755, 59.41012] } },
    { "type": "Feature", "id": 135, "properties": { "store_name": "Olerex, Märjamaa Orgita", "address": "Kõrtsu", "postcode": "", "city": "Orgita küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 136, "properties": { "store_name": "Olerex, Viimsi", "address": "Muuli tee 2/Ranna tee 15", "postcode": "", "city": "Miiduranna küla" }, "geometry": { "type": "Point", "coordinates": [24.82886, 59.49805] } },
    { "type": "Feature", "id": 137, "properties": { "store_name": "Olerex, Tallinn Harkujärve", "address": "Paldiski maantee 150", "postcode": "", "city": "Harkujärve küla" }, "geometry": { "type": "Point", "coordinates": [24.61767, 59.40752] } },
    { "type": "Feature", "id": 138, "properties": { "store_name": "Olerex, Põlva", "address": "Tartu mnt 1b", "postcode": "", "city": "Mammaste küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 139, "properties": { "store_name": "Olerex, Rakvere Rägavere 2", "address": "Rägavere tee 2", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.36843, 59.34542] } },
    { "type": "Feature", "id": 140, "properties": { "store_name": "Olerex, Tallinn Õismäe", "address": "Õismäe tee 155a/157", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.64602, 59.4201] } },
    { "type": "Feature", "id": 141, "properties": { "store_name": "Olerex, Ahtme", "address": "Altserva 1a", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.43361, 59.32504] } },
    { "type": "Feature", "id": 142, "properties": { "store_name": "Olerex, Enge", "address": "Jadivere", "postcode": "", "city": "Jädivere küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 143, "properties": { "store_name": "Olerex, Jüri", "address": "Viadukti põik 6", "postcode": "", "city": "Pildiküla" }, "geometry": { "type": "Point", "coordinates": [24.87434, 59.3594] } },
    { "type": "Feature", "id": 144, "properties": { "store_name": "Olerex, Tallinn Kivila", "address": "Kivila 28", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.87841, 59.44366] } },
    { "type": "Feature", "id": 145, "properties": { "store_name": "Olerex, Pärnu Laki", "address": "Laki 8", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.55294, 58.37478] } },
    { "type": "Feature", "id": 146, "properties": { "store_name": "Olerex, Lemmatsi", "address": "Lemmatsi küla", "postcode": "", "city": "Lemmatsi küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 147, "properties": { "store_name": "Olerex, Lihula", "address": "Alaküla tee 22", "postcode": "", "city": "Hälvati küla" }, "geometry": { "type": "Point", "coordinates": [23.80945, 58.68513] } },
    { "type": "Feature", "id": 148, "properties": { "store_name": "Olerex, Märjamaa Uus tn", "address": "Uus 6", "postcode": "", "city": "Märjamaa alev" }, "geometry": { "type": "Point", "coordinates": [24.41635, 58.89478] } },
    { "type": "Feature", "id": 149, "properties": { "store_name": "Olerex, Paide Raudtee", "address": "Raudtee 37", "postcode": "", "city": "Paide" }, "geometry": { "type": "Point", "coordinates": [25.54139, 58.8876] } },
    { "type": "Feature", "id": 150, "properties": { "store_name": "Olerex, Tallinn Peterburi", "address": "Peterburi tee 38/4", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.81063, 59.42619] } },
    { "type": "Feature", "id": 151, "properties": { "store_name": "Olerex, Tallinn Punane", "address": "Punane 16a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.82342, 59.43257] } },
    { "type": "Feature", "id": 152, "properties": { "store_name": "Olerex, Haapsalu", "address": "Tallinna mnt 75", "postcode": "", "city": "Uuemõisa alevik" }, "geometry": { "type": "Point", "coordinates": [23.57316, 58.93857] } },
    { "type": "Feature", "id": 153, "properties": { "store_name": "Olerex, Rapla", "address": "Kastani 9", "postcode": "", "city": "Rapla" }, "geometry": { "type": "Point", "coordinates": [24.79794, 58.99455] } },
    { "type": "Feature", "id": 154, "properties": { "store_name": "Olerex, Räpina", "address": "Vabaduse 2", "postcode": "", "city": "Räpina" }, "geometry": { "type": "Point", "coordinates": [27.45812, 58.10177] } },
    { "type": "Feature", "id": 155, "properties": { "store_name": "Olerex, Sauga", "address": "Jänesselja 9", "postcode": "", "city": "Sauga alevik" }, "geometry": { "type": "Point", "coordinates": [24.4983, 58.43076] } },
    { "type": "Feature", "id": 156, "properties": { "store_name": "Olerex, Tallinn Sõle", "address": "Sõle 51b", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.70165, 59.44538] } },
    { "type": "Feature", "id": 157, "properties": { "store_name": "Olerex, Tallinn Sõpruse", "address": "Sõpruse pst 261", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.67704, 59.39787] } },
    { "type": "Feature", "id": 158, "properties": { "store_name": "Olerex, Tallinn Tammsaare", "address": "Tammsaare tee 53a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.70985, 59.40204] } },
    { "type": "Feature", "id": 159, "properties": { "store_name": "Olerex, Tapa", "address": "Pikk 70", "postcode": "", "city": "Tapa" }, "geometry": { "type": "Point", "coordinates": [25.97502, 59.25349] } },
    { "type": "Feature", "id": 160, "properties": { "store_name": "Olerex, Tallinn Tondiraba", "address": "Tondiraba 1", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.85416, 59.45161] } },
    { "type": "Feature", "id": 161, "properties": { "store_name": "Olerex, Türi", "address": "Tehnika 6", "postcode": "", "city": "Lokuta küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 162, "properties": { "store_name": "Olerex, Tallinn Vabaduse", "address": "Vabaduse pst 158", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.64595, 59.36685] } },
    { "type": "Feature", "id": 163, "properties": { "store_name": "Olerex, Valga", "address": "Transpordi 2", "postcode": "", "city": "Valga" }, "geometry": { "type": "Point", "coordinates": [26.0495, 57.79052] } },
    { "type": "Feature", "id": 164, "properties": { "store_name": "Olerex, Pärnu Papsaare", "address": "Papsaare küla", "postcode": "", "city": "Papsaare küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 165, "properties": { "store_name": "Olerex, Võru Räpina", "address": "Räpina mnt 18", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [27.02067, 57.84418] } },
    { "type": "Feature", "id": 166, "properties": { "store_name": "Olerex, Kunda", "address": "Selja tee 7", "postcode": "", "city": "Kunda" }, "geometry": { "type": "Point", "coordinates": [26.51675, 59.49583] } },
    { "type": "Feature", "id": 167, "properties": { "store_name": "Olerex, Narva", "address": "Tallinna mnt 73", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.15801, 59.38496] } },
    { "type": "Feature", "id": 168, "properties": { "store_name": "Olerex, Rakvere Piira", "address": "", "postcode": "", "city": "Mäetaguse küla" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 169, "properties": { "store_name": "Olerex, Rakvere Põhjakeskus", "address": "Haljala tee 17", "postcode": "", "city": "Tõrremäe küla" }, "geometry": { "type": "Point", "coordinates": [26.33472, 59.36232] } },
    { "type": "Feature", "id": 170, "properties": { "store_name": "Olerex, Haage", "address": "Side põik 1a", "postcode": "", "city": "Haage küla" }, "geometry": { "type": "Point", "coordinates": [26.62088, 58.35662] } },
    { "type": "Feature", "id": 171, "properties": { "store_name": "Olerex, Ahja", "address": "Tartu mnt 17", "postcode": "", "city": "Ahja alevik" }, "geometry": { "type": "Point", "coordinates": [27.07434, 58.20637] } },
    { "type": "Feature", "id": 172, "properties": { "store_name": "Olerex, Vastseliina", "address": "Tootmise 2", "postcode": "", "city": "Vastseliina alevik" }, "geometry": { "type": "Point", "coordinates": [27.27345, 57.7319] } },
    { "type": "Feature", "id": 173, "properties": { "store_name": "Olerex, Värska", "address": "Pikk tn 36", "postcode": "", "city": "Värska alevik" }, "geometry": { "type": "Point", "coordinates": [27.64054, 57.95337] } },
    { "type": "Feature", "id": 174, "properties": { "store_name": "Olerex, Tallinn Kadaka", "address": "Kadaka tee 66b", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6613, 59.40928] } },
    { "type": "Feature", "id": 175, "properties": { "store_name": "Olerex, Keila", "address": "Põhjakaare 8", "postcode": "", "city": "Keila" }, "geometry": { "type": "Point", "coordinates": [24.42748, 59.31251] } },
    { "type": "Feature", "id": 176, "properties": { "store_name": "Olerex, Tartu Ringtee", "address": "Ringtee 28", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.73749, 58.34354] } },
    { "type": "Feature", "id": 177, "properties": { "store_name": "Olerex, Tallinn Tondi", "address": "Tondi 6", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.73842, 59.41497] } },
    { "type": "Feature", "id": 178, "properties": { "store_name": "Olerex, Viljandi Mustivere", "address": "Eerikaru 2", "postcode": "", "city": "Mustivere" }, "geometry": { "type": "Point", "coordinates": [25.56326, 58.37225] } },
    { "type": "Feature", "id": 179, "properties": { "store_name": "Olerex, Tallinn Linnu", "address": "Linnu tee 64", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.70885, 59.41296] } },
    { "type": "Feature", "id": 180, "properties": { "store_name": "Olerex, Tallinn Peetri", "address": "Tartu mnt 169", "postcode": "", "city": "Peetri alevik" }, "geometry": { "type": "Point", "coordinates": [24.81627, 59.40106] } },
    { "type": "Feature", "id": 181, "properties": { "store_name": "Olerex, Mustvee", "address": "Tartu tn. 74", "postcode": "", "city": "Mustvee linn" }, "geometry": { "type": "Point", "coordinates": [26.91528, 58.84828] } },
    { "type": "Feature", "id": 182, "properties": { "store_name": "Olerex, Tartu Aardla", "address": "Võru 176", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.72182, 58.35531] } },
    { "type": "Feature", "id": 183, "properties": { "store_name": "Olerex, Tartu Kvissentali", "address": "Aruküla tee 1", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.70954, 58.39886] } },
    { "type": "Feature", "id": 184, "properties": { "store_name": "Olerex, Kilingi-Nõmme", "address": "Viigi", "postcode": "", "city": "Väljaküla" }, "geometry": { "type": "Point", "coordinates": [24.99238, 58.15782] } },
    { "type": "Feature", "id": 185, "properties": { "store_name": "Olerex, Otepää", "address": "Tartu mnt 16", "postcode": "", "city": "Otepää" }, "geometry": { "type": "Point", "coordinates": [26.49603, 58.05994] } },
    { "type": "Feature", "id": 186, "properties": { "store_name": "Olerex, Turba", "address": "Jaama tee 18", "postcode": "", "city": "Turba alevik" }, "geometry": { "type": "Point", "coordinates": [24.23596, 59.08327] } },
    { "type": "Feature", "id": 187, "properties": { "store_name": "Lennujaama reisiterminali Tallinn Duty-Free kauplus", "address": "Tartu mnt 101", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.79915, 59.41618] } },
    { "type": "Feature", "id": 188, "properties": { "store_name": "Rimi CHM Pärnu ", "address": "Papiniidu 8/10", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.55129, 58.37113] } },
    { "type": "Feature", "id": 189, "properties": { "store_name": "Rimi LHM Ülemiste", "address": "Suur-Sõjamäe 4", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7944, 59.42204] } },
    { "type": "Feature", "id": 190, "properties": { "store_name": "Rimi CHM Fama", "address": "Fama tn 10", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.18722, 59.37988] } },
    { "type": "Feature", "id": 191, "properties": { "store_name": "Rimi SHM Rakvere", "address": "Tõrremäe küla", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 192, "properties": { "store_name": "Rimi LHM Lõunakeskus", "address": "Ringtee 75", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.6782, 58.35846] } },
    { "type": "Feature", "id": 193, "properties": { "store_name": "Rimi CHM Laagri ", "address": "Pärnu mnt 556A", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.62162, 59.34602] } },
    { "type": "Feature", "id": 194, "properties": { "store_name": "Rimi SHM Magistrali ", "address": "Sõpruse pst 201", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.69752, 59.40153] } },
    { "type": "Feature", "id": 195, "properties": { "store_name": "Rimi SHM Peterburi Tee", "address": "Peterburi tee 98", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.89948, 59.44098] } },
    { "type": "Feature", "id": 196, "properties": { "store_name": "Rimi SHM Viljandi ", "address": "Tallinna mnt 41", "postcode": "", "city": "Viljandi" }, "geometry": { "type": "Point", "coordinates": [25.59771, 58.36985] } },
    { "type": "Feature", "id": 197, "properties": { "store_name": "Rimi SHM Tammsaare ", "address": "Tammsaare tee 104A", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.68886, 59.40869] } },
    { "type": "Feature", "id": 198, "properties": { "store_name": "Rimi SHM Viimsi Market ", "address": "Randvere tee 9", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.84494, 59.4766] } },
    { "type": "Feature", "id": 199, "properties": { "store_name": "Rimi MHM Pae ", "address": "Pae 76", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.82198, 59.43646] } },
    { "type": "Feature", "id": 200, "properties": { "store_name": "Rimi CHM Haabersti ", "address": "Haabersti 1", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.64403, 59.42508] } },
    { "type": "Feature", "id": 201, "properties": { "store_name": "Rimi CHM Sõpruse ", "address": "Sõpruse pst 174/176", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.70723, 59.41477] } },
    { "type": "Feature", "id": 202, "properties": { "store_name": "Rimi LHM Mustakivi ", "address": "Mustakivi tee 13", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.86402, 59.44063] } },
    { "type": "Feature", "id": 203, "properties": { "store_name": "Rimi CHM Norde ", "address": "Ahtri 9", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.76295, 59.44149] } },
    { "type": "Feature", "id": 204, "properties": { "store_name": "Rimi SHM Rebase ", "address": "Rebase 10", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.73897, 58.36844] } },
    { "type": "Feature", "id": 205, "properties": { "store_name": "Rimi SM Maardu ", "address": "Keemikute 25", "postcode": "", "city": "Maardu" }, "geometry": { "type": "Point", "coordinates": [25.01545, 59.48022] } },
    { "type": "Feature", "id": 206, "properties": { "store_name": "Rimi SM Vilde", "address": "Vilde tee 101", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.67612, 59.40457] } },
    { "type": "Feature", "id": 207, "properties": { "store_name": "Rimi SM Saue ", "address": "Keskuse 11", "postcode": "", "city": "Saue" }, "geometry": { "type": "Point", "coordinates": [24.56094, 59.32461] } },
    { "type": "Feature", "id": 208, "properties": { "store_name": "Rimi SM Astangu ", "address": "Astangu 2a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.61591, 59.40538] } },
    { "type": "Feature", "id": 209, "properties": { "store_name": "Rimi SM Kaubahall", "address": "Aia 7", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.75078, 59.43839] } },
    { "type": "Feature", "id": 210, "properties": { "store_name": "Rimi SM Põhja ", "address": "Põhja pst 17", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.74281, 59.4443] } },
    { "type": "Feature", "id": 211, "properties": { "store_name": "Rimi SM Nurmenuku", "address": "Ehitajate tee 107", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.65516, 59.41374] } },
    { "type": "Feature", "id": 212, "properties": { "store_name": "Rimi SM Kuressaare ", "address": "Tallinna mnt 88", "postcode": "", "city": "Kuressaare" }, "geometry": { "type": "Point", "coordinates": [22.51652, 58.26516] } },
    { "type": "Feature", "id": 213, "properties": { "store_name": "Rimi SM Võru ", "address": "Jüri tn. 85", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [27.01009, 57.83464] } },
    { "type": "Feature", "id": 214, "properties": { "store_name": "Rimi SM Tabasalu ", "address": "Klooga mnt 10B", "postcode": "", "city": "Tabasalu" }, "geometry": { "type": "Point", "coordinates": [24.54372, 59.4301] } },
    { "type": "Feature", "id": 215, "properties": { "store_name": "Rimi SM Tasku", "address": "Turu 2", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.73064, 58.3782] } },
    { "type": "Feature", "id": 216, "properties": { "store_name": "Rimi SM Haapsalu", "address": "Jaama 32", "postcode": "", "city": "Haapsalu" }, "geometry": { "type": "Point", "coordinates": [23.54043, 58.93916] } },
    { "type": "Feature", "id": 217, "properties": { "store_name": "Rimi SM Postimaja ", "address": "Narva mnt 1", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.75621, 59.43784] } },
    { "type": "Feature", "id": 218, "properties": { "store_name": "Rimi SM Sõle ", "address": "Paavli 10", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.70225, 59.44708] } },
    { "type": "Feature", "id": 219, "properties": { "store_name": "Rimi SM Telliskivi ", "address": "Telliskivi 61", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.73007, 59.44119] } },
    { "type": "Feature", "id": 220, "properties": { "store_name": "Rimi SM Pirita ", "address": "Merivälja tee 24", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8352, 59.46883] } },
    { "type": "Feature", "id": 221, "properties": { "store_name": "Rimi SM Tööstuse ", "address": "Tööstuse 101", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.70565, 59.45383] } },
    { "type": "Feature", "id": 222, "properties": { "store_name": "Rimi SM Sepa", "address": "Sepa 21", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.73958, 58.34949] } },
    { "type": "Feature", "id": 223, "properties": { "store_name": "Rimi SM Pärnamäe", "address": "Lilleoru tee 4", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.90585, 59.46319] } },
    { "type": "Feature", "id": 224, "properties": { "store_name": "Rimi SM Hiiu     ", "address": "Pärnu mnt 386 ", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.66551, 59.3826] } },
    { "type": "Feature", "id": 225, "properties": { "store_name": "Rimi SM Pärnu Turu ", "address": "Suur Sepa 18", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.50919, 58.3822] } },
    { "type": "Feature", "id": 226, "properties": { "store_name": "Rimi SM Männiku", "address": "Männiku tee 100", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.71627, 59.3712] } },
    { "type": "Feature", "id": 227, "properties": { "store_name": "Rimi SM Nurmenuku", "address": "Ehitajate tee 107", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6552, 59.41374] } },
    { "type": "Feature", "id": 228, "properties": { "store_name": "Rimi MR Martensi ", "address": "Pikk tn. 12", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.50434, 58.38659] } },
    { "type": "Feature", "id": 229, "properties": { "store_name": "Rimi MR Juhkentali ", "address": "Juhkentali 35", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.76989, 59.42773] } },
    { "type": "Feature", "id": 230, "properties": { "store_name": "Rimi MR Tatari ", "address": "Tatari 64", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.74623, 59.42585] } },
    { "type": "Feature", "id": 231, "properties": { "store_name": "Rimi MR Kesklinna ", "address": "Küüni 7", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.72469, 58.37824] } },
    { "type": "Feature", "id": 232, "properties": { "store_name": "Rimi MR Ülejõe ", "address": "Jannseni 3", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.49384, 58.39195] } },
    { "type": "Feature", "id": 233, "properties": { "store_name": "Rimi MR Rakvere ", "address": "Vilde 8", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.36547, 59.34634] } },
    { "type": "Feature", "id": 234, "properties": { "store_name": "Rimi MR Pääsküla", "address": "Pärnu mnt 453e", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.63167, 59.36009] } },
    { "type": "Feature", "id": 235, "properties": { "store_name": "Rimi MR Finest ", "address": "Pärnu mnt 22", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.74525, 59.43177] } },
    { "type": "Feature", "id": 236, "properties": { "store_name": "Rimi MR Saku ", "address": "Tallinna mnt 4", "postcode": "", "city": "Saku" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 237, "properties": { "store_name": "Rimi MR Rapla ", "address": "Tallinna mnt. 46a", "postcode": "", "city": "Rapla" }, "geometry": { "type": "Point", "coordinates": [24.7915, 58.99989] } },
    { "type": "Feature", "id": 238, "properties": { "store_name": "Rimi MR Jaama", "address": "Jaama 12", "postcode": "", "city": "Haapsalu" }, "geometry": { "type": "Point", "coordinates": [23.53538, 58.93926] } },
    { "type": "Feature", "id": 239, "properties": { "store_name": "Rimi MR Kärdla ", "address": "Kõrgessaare mnt 25", "postcode": "", "city": "Kärdla" }, "geometry": { "type": "Point", "coordinates": [22.73457, 58.99324] } },
    { "type": "Feature", "id": 240, "properties": { "store_name": "Rimi MR Põltsamaa ", "address": "Tartu mnt 12", "postcode": "", "city": "Põltsamaa" }, "geometry": { "type": "Point", "coordinates": [25.98215, 58.65346] } },
    { "type": "Feature", "id": 241, "properties": { "store_name": "Rimi MR Märjamaa ", "address": "Pärnu mnt 76", "postcode": "", "city": "Märjamaa" }, "geometry": { "type": "Point", "coordinates": [24.42203, 58.9017] } },
    { "type": "Feature", "id": 242, "properties": { "store_name": "Rimi MR Põlva ", "address": "Jaama 12B", "postcode": "", "city": "Põlva" }, "geometry": { "type": "Point", "coordinates": [27.07012, 58.06293] } },
    { "type": "Feature", "id": 243, "properties": { "store_name": "Rimi MR Aardla ", "address": "Võru 170", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.72253, 58.3563] } },
    { "type": "Feature", "id": 244, "properties": { "store_name": "Rimi MR Tuglase", "address": "Tuglase 19", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.69191, 58.38499] } },
    { "type": "Feature", "id": 245, "properties": { "store_name": "Rimi MR Türi ", "address": "Paide 26", "postcode": "", "city": "Türi" }, "geometry": { "type": "Point", "coordinates": [25.42998, 58.81247] } },
    { "type": "Feature", "id": 246, "properties": { "store_name": "Rimi MR Kohtla-Järve", "address": "Põhja allee 4", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.28477, 59.40733] } },
    { "type": "Feature", "id": 247, "properties": { "store_name": "Rimi MR Elva ", "address": "Kesk 7", "postcode": "", "city": "Elva" }, "geometry": { "type": "Point", "coordinates": [26.4105, 58.22151] } },
    { "type": "Feature", "id": 248, "properties": { "store_name": "Rimi EXP Vanalinna ", "address": "Pikk 11", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.74448, 59.43824] } },
    { "type": "Feature", "id": 249, "properties": { "store_name": "Rimi EXP Karamelli", "address": "Pärnu mnt 139a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.73475, 59.40745] } },
    { "type": "Feature", "id": 250, "properties": { "store_name": "Rimi EXP Tornimäe ", "address": "Tartu mnt 10", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.76041, 59.43428] } },
    { "type": "Feature", "id": 251, "properties": { "store_name": "R-kiosk, Vabaduse Väljak", "address": "Vabaduse Väljak 2a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.74621, 59.43495] } },
    { "type": "Feature", "id": 252, "properties": { "store_name": "R-kiosk, Nunne", "address": "Nunne 17", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7394, 59.4396] } },
    { "type": "Feature", "id": 253, "properties": { "store_name": "R-kiosk, Balti Jaam 2", "address": "Toompuiestee 37", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.73736, 59.44002] } },
    { "type": "Feature", "id": 254, "properties": { "store_name": "R-kiosk, Pirita", "address": "Merivälja tee 1", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.83247, 59.46684] } },
    { "type": "Feature", "id": 255, "properties": { "store_name": "R-kiosk, Majaka", "address": "Majaka 59", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.79799, 59.42646] } },
    { "type": "Feature", "id": 256, "properties": { "store_name": "R-kiosk, Katleri", "address": "Katleri 11", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.84969, 59.45106] } },
    { "type": "Feature", "id": 257, "properties": { "store_name": "R-kiosk, Uus Pae", "address": "Pae 64", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.81912, 59.43573] } },
    { "type": "Feature", "id": 258, "properties": { "store_name": "R-kiosk, Viru Tunnel", "address": "Viru väljak 4", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.75591, 59.43667] } },
    { "type": "Feature", "id": 259, "properties": { "store_name": "R-kiosk, Viru Keskus", "address": "Viru väljak 4/6", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.75596, 59.43666] } },
    { "type": "Feature", "id": 260, "properties": { "store_name": "R-kiosk, Viru Bussiterminal", "address": "Viru väljak 4/6", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.75596, 59.43666] } },
    { "type": "Feature", "id": 261, "properties": { "store_name": "R-kiosk, Ülemiste Center", "address": "Suur-Sõjamäe tn 4", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7914, 59.42091] } },
    { "type": "Feature", "id": 262, "properties": { "store_name": "R-kiosk, Tallinna Lennujaama välitsoon", "address": "Tartu mnt 101", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.79913, 59.41619] } },
    { "type": "Feature", "id": 263, "properties": { "store_name": "R-kiosk, Tallinna Lennujaama sisetsoon", "address": "Tartu mnt 101, sisetsoon", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.80011, 59.41649] } },
    { "type": "Feature", "id": 264, "properties": { "store_name": "R-kiosk, Öpiku Maja", "address": "Valukoja 8", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8047968270695, 59.420115830782] } },
    { "type": "Feature", "id": 265, "properties": { "store_name": "R-kiosk, Ülemiste City 2", "address": "Sepa-Paja tn 2 ", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8032491424112, 59.4218040924765] } },
    { "type": "Feature", "id": 266, "properties": { "store_name": "R-kiosk, Laikmaa", "address": "Viru väljak 4", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7551467270702, 59.4361465264822] } },
    { "type": "Feature", "id": 267, "properties": { "store_name": "R-kiosk, Vabaduse Tunnel", "address": "Vabaduse väljak 7", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7436510405638, 59.4325160311638] } },
    { "type": "Feature", "id": 268, "properties": { "store_name": "R-kiosk, Viljandi Bussijaam", "address": "Ilmarise tn 1", "postcode": "", "city": "Viljandi" }, "geometry": { "type": "Point", "coordinates": [25.598667527019, 58.3692783212891] } },
    { "type": "Feature", "id": 269, "properties": { "store_name": "R-kiosk, Kuressaare Kesklinn", "address": "Tallinna mnt 8", "postcode": "", "city": "Kuressaare" }, "geometry": { "type": "Point", "coordinates": [22.4865738270135, 58.253333629265] } },
    { "type": "Feature", "id": 270, "properties": { "store_name": "R-kiosk, Lauteri", "address": "Lauteri 9", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7585010270699, 59.4308771590895] } },
    { "type": "Feature", "id": 271, "properties": { "store_name": "R-kiosk, Kristiine sise", "address": "Endla tn 45", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7240741559052, 59.4276329793224] } },
    { "type": "Feature", "id": 272, "properties": { "store_name": "R-kiosk, Kaubamaja", "address": "Estonia pst 1", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.754907840564, 59.4352668391056] } },
    { "type": "Feature", "id": 273, "properties": { "store_name": "R-kiosk, Maardu", "address": "Keemikute tn 37", "postcode": "", "city": "Maardu" }, "geometry": { "type": "Point", "coordinates": [25.0171231405662, 59.4827933105548] } },
    { "type": "Feature", "id": 274, "properties": { "store_name": "R-kiosk, Peterburi Neste", "address": "Peterburi tee 52", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8230023982344, 59.4267934669887] } },
    { "type": "Feature", "id": 275, "properties": { "store_name": "R-kiosk, Smuuli Neste", "address": "Punane tn 43", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8322202693993, 59.43466717255] } },
    { "type": "Feature", "id": 276, "properties": { "store_name": "R-kiosk, Kodutarve sise", "address": "Narva mnt 19/21/23", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7708994693996, 59.4386643544258] } },
    { "type": "Feature", "id": 277, "properties": { "store_name": "R-kiosk, Postimaja", "address": "Narva mnt 1", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7562031117287, 59.4377246137543] } },
    { "type": "Feature", "id": 278, "properties": { "store_name": "R-kiosk, Foorum Keskus", "address": "Narva mnt 5", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7584663424119, 59.4384383339929] } },
    { "type": "Feature", "id": 279, "properties": { "store_name": "R-kiosk, Energia", "address": "Kaubamaja tn 4", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7553784405639, 59.435037727912] } },
    { "type": "Feature", "id": 280, "properties": { "store_name": "R-kiosk, Sadama D Terminal", "address": "Lootsi tn 13", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7633337982351, 59.4431332719477] } },
    { "type": "Feature", "id": 281, "properties": { "store_name": "R-kiosk, Väike-Õismäe", "address": "Õismäe tee 105", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6387920270691, 59.4123941137104] } },
    { "type": "Feature", "id": 282, "properties": { "store_name": "R-kiosk, Kadaka Neste", "address": "Kadaka tee 60", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6648142135753, 59.4118007745816] } },
    { "type": "Feature", "id": 283, "properties": { "store_name": "R-kiosk, Forelli Neste", "address": "Forelli 2a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.693617742411, 59.4187705993219] } },
    { "type": "Feature", "id": 284, "properties": { "store_name": "R-kiosk, Laagri Neste", "address": "Pärnu mnt 453e", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6317067270665, 59.3599867246551] } },
    { "type": "Feature", "id": 285, "properties": { "store_name": "R-kiosk, Rocca-al-Mare Neste", "address": "Paldiski mnt 98", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6607729559053, 59.4274455087198] } },
    { "type": "Feature", "id": 286, "properties": { "store_name": "SR-kiosk, õle Neste", "address": "Sõle tn 25a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7084061405641, 59.4378334243067] } },
    { "type": "Feature", "id": 287, "properties": { "store_name": "R-kiosk, Tallinna Bussijaama välikiosk", "address": "Lastekodu tn 46", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7740350559052, 59.4274630516847] } },
    { "type": "Feature", "id": 288, "properties": { "store_name": "R-kiosk, Roosikrantsi", "address": "Roosikrantsi tn 2 / Kaarli pst 1", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7427738405638, 59.4324274247094] } },
    { "type": "Feature", "id": 289, "properties": { "store_name": "R-kiosk, Sadama A Terminal", "address": "Sadama tn 25/2", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7618855712477, 59.4448450404753] } },
    { "type": "Feature", "id": 290, "properties": { "store_name": "R-kiosk, Sitsi", "address": "Sitsi tn 3", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7032133559064, 59.4525422760644] } },
    { "type": "Feature", "id": 291, "properties": { "store_name": "R-kiosk, Nurmenuku Keskus", "address": "Õismäe tee 1b", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6545328270691, 59.4138555328145] } },
    { "type": "Feature", "id": 292, "properties": { "store_name": "R-kiosk, Rautakesko Tondi", "address": "Tammsaare tee 49", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7132700982332, 59.4016405321361] } },
    { "type": "Feature", "id": 293, "properties": { "store_name": "R-kiosk, Rocca-al-Mare Kaubanduskeskus", "address": "Paldiski mnt 102", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6497241693989, 59.4249062280571] } },
    { "type": "Feature", "id": 294, "properties": { "store_name": "R-kiosk, Keskturg", "address": "Keldrimäe tn 9/Lastekodu tn 12", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 295, "properties": { "store_name": "R-kiosk, Jõhvi Bussijaam", "address": "Narva mnt 8", "postcode": "", "city": "Jõhvi" }, "geometry": { "type": "Point", "coordinates": [27.4178549270665, 59.3580131727837] } },
    { "type": "Feature", "id": 296, "properties": { "store_name": "R-kiosk, Jõhvi Pargi Keskus", "address": "Puru tee 1", "postcode": "", "city": "Jõhvi" }, "geometry": { "type": "Point", "coordinates": [27.4200040693953, 59.3524964179119] } },
    { "type": "Feature", "id": 297, "properties": { "store_name": "R-kiosk, Jõhvi Tsentraal", "address": "Keskväljak 4", "postcode": "", "city": "Jõhvi" }, "geometry": { "type": "Point", "coordinates": [27.4140732847373, 59.3583638993059] } },
    { "type": "Feature", "id": 298, "properties": { "store_name": "R-kiosk, Sillamäe Viru Pst.", "address": "Viru pst 5a", "postcode": "", "city": "Sillamäe" }, "geometry": { "type": "Point", "coordinates": [27.7779419559035, 59.3916149722391] } },
    { "type": "Feature", "id": 299, "properties": { "store_name": "R-kiosk, Rapla Mahlamäe", "address": "Mahlamäe tn 3", "postcode": "", "city": "Rapla" }, "geometry": { "type": "Point", "coordinates": [24.793790440543, 58.999323714388] } },
    { "type": "Feature", "id": 300, "properties": { "store_name": "R-kiosk, Tartu Kuperjanovi", "address": "J.Kuperjanovi 11a", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7102266981839, 58.3760738836396] } },
    { "type": "Feature", "id": 301, "properties": { "store_name": "R-kiosk, Ämari Sõjaväelinnak", "address": "Ämari lennuväli", "postcode": "", "city": "Ämari" }, "geometry": { "type": "Point", "coordinates": [24.2235048847326, 59.261995201829] } },
    { "type": "Feature", "id": 302, "properties": { "store_name": "R-kiosk, Tallinna Bussijaama ootesaal", "address": "Lastekodu tn 46", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.774002869399, 59.4274412236987] } },
    { "type": "Feature", "id": 303, "properties": { "store_name": "R-kiosk, Solarise Kiosk", "address": "Estonia pst 9", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7513636693993, 59.4332824105042] } },
    { "type": "Feature", "id": 304, "properties": { "store_name": "R-kiosk, Magistrali Keskus", "address": "Sôpruse pst 201/203", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6976637270686, 59.4016554932778] } },
    { "type": "Feature", "id": 305, "properties": { "store_name": "R-kiosk, Pärnu Rüütli Combo", "address": "Rüütli tn 41", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.5025700693489, 58.3846512781715] } },
    { "type": "Feature", "id": 306, "properties": { "store_name": "R-kiosk, Pärnu Bussijaama Kohvik", "address": "Pikk tn 13", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.5049462558552, 58.3860061972891] } },
    { "type": "Feature", "id": 307, "properties": { "store_name": "R-kiosk, Pärnu Turg", "address": "Suur-Sepa tn 18", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.509236355855, 58.3821065275053] } },
    { "type": "Feature", "id": 308, "properties": { "store_name": "R-kiosk, Praam Saaremaa 'Tõll", "address": "", "postcode": "", "city": "Saaremaa liin" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 309, "properties": { "store_name": "R-kiosk, Praam Saaremaa 'Piret", "address": "", "postcode": "", "city": "Saaremaa liin" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 310, "properties": { "store_name": "R-kiosk, Praam Hiiumaa 'Leiger", "address": "", "postcode": "", "city": "Hiiumaa liin" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 311, "properties": { "store_name": "R-kiosk, Praam Hiiumaa 'Tiiu", "address": "", "postcode": "", "city": "Hiiumaa liin" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 312, "properties": { "store_name": "R-kiosk, Tartu Bussijaam", "address": "Turu tn 2", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.730658098184, 58.3781101591354] } },
    { "type": "Feature", "id": 313, "properties": { "store_name": "R-kiosk, Tartu Küüni", "address": "Vanemuise tn 1", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7261111270195, 58.3788392327142] } },
    { "type": "Feature", "id": 314, "properties": { "store_name": "R-kiosk, Kohtla-Järve Kalevi tn.", "address": "Kalevi tn 25b", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.2833316559042, 59.4048743487361] } },
    { "type": "Feature", "id": 315, "properties": { "store_name": "R-kiosk, Kiviõli", "address": "Metsa 3", "postcode": "", "city": "Kiviõli" }, "geometry": { "type": "Point", "coordinates": [26.9754048559018, 59.3570225968291] } },
    { "type": "Feature", "id": 316, "properties": { "store_name": "R-kiosk, Kohtla-Järve Virula Väljak", "address": "Keskallee 7b", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.2883399693978, 59.4024696699024] } },
    { "type": "Feature", "id": 317, "properties": { "store_name": "R-kiosk, Kohtla-Järve Maleva sise", "address": "Maleva tn 25c", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.4196416117234, 59.3279510389649] } },
    { "type": "Feature", "id": 318, "properties": { "store_name": "R-kiosk, Narva Vaksali Väljak", "address": "Vaksali tn 25", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.1994366982316, 59.3691469792525] } },
    { "type": "Feature", "id": 319, "properties": { "store_name": "R-kiosk, Narva Astri Keskus", "address": "Tallinna mnt. 41", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.1759640559029, 59.3810127910299] } },
    { "type": "Feature", "id": 320, "properties": { "store_name": "R-kiosk, Narva Fama Keskus", "address": "Fama põik 10", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.1876661693967, 59.3799182283023] } },
    { "type": "Feature", "id": 321, "properties": { "store_name": "R-kiosk, Rakvere Bussijaam Sise", "address": "Laada tn 18a", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.3644503405597, 59.3467140618617] } },
    { "type": "Feature", "id": 322, "properties": { "store_name": "R-kiosk, Rakvere Põhjakeskus", "address": "Haljala tee 4", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.3374760693959, 59.3629476208399] } },
    { "type": "Feature", "id": 323, "properties": { "store_name": "R-kiosk, Tartu Neste BiteStop", "address": "Ringtee tn 60a", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.6783410981839, 58.3754985123654] } },
    { "type": "Feature", "id": 324, "properties": { "store_name": "R-kiosk, Tartu Ropka", "address": "Tähe tn 98", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7315809981831, 58.3592366487682] } },
    { "type": "Feature", "id": 325, "properties": { "store_name": "R-kiosk, Tartu Eeden (Kalda kaubanduskeskus)", "address": "Kalda tee 1c", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7503551270192, 58.3730809722069] } },
    { "type": "Feature", "id": 326, "properties": { "store_name": "R-kiosk, Tartu Kvartali Keskus", "address": "Riia tn 2", "postcode": "", "city": "Riia tn 2" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 327, "properties": { "store_name": "R-kiosk, Pärnu Atlantika", "address": "Tallinna mnt 2", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.4949234251722, 58.3908656155347] } },
    { "type": "Feature", "id": 328, "properties": { "store_name": "Tallink Star", "address": "Uus-Sadama 24", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.767289755906, 59.4433810715882] } },
    { "type": "Feature", "id": 329, "properties": { "store_name": "Tallink Megastar", "address": "Uus-Sadama 24", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.767289755906, 59.4433810715882] } },
    { "type": "Feature", "id": 330, "properties": { "store_name": "Tallink Mystar", "address": "Uus-Sadama 24", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.767289755906, 59.4433810715882] } },
    { "type": "Feature", "id": 331, "properties": { "store_name": "D-terminal", "address": "Uus-Sadama 24", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.767289755906, 59.4433810715882] } },
    { "type": "Feature", "id": 332, "properties": { "store_name": "Tallink Express", "address": "Reidi tee 9/3", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7871519135768, 59.4432326896507] } },
    { "type": "Feature", "id": 333, "properties": { "store_name": "Araxes Astangu", "address": "Astangu 50a, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6350291405624, 59.404053550191] } },
    { "type": "Feature", "id": 334, "properties": { "store_name": "Araxes Ehitaja te tee", "address": "Ehitajate tee 109, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6555756270691, 59.4132243781502] } },
    { "type": "Feature", "id": 335, "properties": { "store_name": "Araxes Kari", "address": "Kari 1,  Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6978265694, 59.448418486038] } },
    { "type": "Feature", "id": 336, "properties": { "store_name": "Araxes Katleri", "address": "Katleri 25,  Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8525588828939, 59.4512535133328] } },
    { "type": "Feature", "id": 337, "properties": { "store_name": "Araxes Kopli 4", "address": "Kopli 4, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7367803559059, 59.4414315934255] } },
    { "type": "Feature", "id": 338, "properties": { "store_name": "Araxes Kopli 69", "address": "Kopli 69a, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6909084405649, 59.4546818604203] } },
    { "type": "Feature", "id": 339, "properties": { "store_name": "Araxes Kärberi", "address": "K.Kärberi 34a, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8866949270707, 59.4456656142063] } },
    { "type": "Feature", "id": 340, "properties": { "store_name": "Araxes Laagna", "address": "Laagna tee 80, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8894475982352, 59.4444107786362] } },
    { "type": "Feature", "id": 341, "properties": { "store_name": "Araxes Linnamäe", "address": "Linnamäe tee 37A, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8744501405647, 59.4505313743529] } },
    { "type": "Feature", "id": 342, "properties": { "store_name": "Araxes Mahtra", "address": "Mahtra 1, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8735288405643, 59.4421923464629] } },
    { "type": "Feature", "id": 343, "properties": { "store_name": "Araxes Majaka", "address": "Majaka 56, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7974198693989, 59.4260738256105] } },
    { "type": "Feature", "id": 344, "properties": { "store_name": "Araxes Mustamäe tee 116", "address": "Mustamäe tee 116, Tallinn ", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6794368559041, 59.4038306857177] } },
    { "type": "Feature", "id": 345, "properties": { "store_name": "Araxes Mustamäe tee 43", "address": "Mustamae tee 43,  Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6989957982342, 59.422308459993] } },
    { "type": "Feature", "id": 346, "properties": { "store_name": "Araxes Niidi põik", "address": "Niidi põik 3, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6961021982356, 59.4514076193714] } },
    { "type": "Feature", "id": 347, "properties": { "store_name": "Araxes Pae 20", "address": "Pae 20, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8059058982345, 59.429141646131] } },
    { "type": "Feature", "id": 348, "properties": { "store_name": "Araxes Pae 74", "address": "Pae 74, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8230119270702, 59.4358311495063] } },
    { "type": "Feature", "id": 349, "properties": { "store_name": "Araxes Pae 80", "address": "Pae 80, Tallinn ", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8208055540578, 59.4362921265345] } },
    { "type": "Feature", "id": 350, "properties": { "store_name": "Araxes Pinna", "address": "P.Pinna 21, Tallinn ", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.832890898235, 59.439086339426] } },
    { "type": "Feature", "id": 351, "properties": { "store_name": "Araxes Pärnu mnt.", "address": "Pärnu mnt 240,  Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7201788559035, 59.3920519714646] } },
    { "type": "Feature", "id": 352, "properties": { "store_name": "Araxes Punane", "address": "Punane 17a, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8192100847408, 59.4327222486436] } },
    { "type": "Feature", "id": 353, "properties": { "store_name": "Araxes Rännaku", "address": "Rännaku pst 9a", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6452211117253, 59.3679497805718] } },
    { "type": "Feature", "id": 354, "properties": { "store_name": "Araxes Sõle", "address": "Sõle 42a,  Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7053104693998, 59.4432279985909] } },
    { "type": "Feature", "id": 355, "properties": { "store_name": "Araxes Sõpruse", "address": "Sõpruse pst 225, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6890352405623, 59.399411504532] } },
    { "type": "Feature", "id": 356, "properties": { "store_name": "Araxes Tatari", "address": "Tatari 64,  Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7443029982344, 59.4254480709239] } },
    { "type": "Feature", "id": 357, "properties": { "store_name": "Araxes Tulika", "address": "Tulika 31,  Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7215013847406, 59.4272172802606] } },
    { "type": "Feature", "id": 358, "properties": { "store_name": "Araxes Vikerlase", "address": "Vikerlase 23, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8458349828934, 59.4395243278078] } },
    { "type": "Feature", "id": 359, "properties": { "store_name": "Araxes Vilde", "address": "Vilde tee 101, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6761107693979, 59.4045245528782] } },
    { "type": "Feature", "id": 360, "properties": { "store_name": "Araxes Virbi", "address": "Virbi 16, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8442112693997, 59.4414751385878] } },
    { "type": "Feature", "id": 361, "properties": { "store_name": "Araxes Õismäe tee", "address": "Õismäe tee 107a, Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6375677559045, 59.4118388554378] } },
    { "type": "Feature", "id": 362, "properties": { "store_name": "Araxes Nurga", "address": "Nurga 5, Maardu", "postcode": "", "city": "Maardu" }, "geometry": { "type": "Point", "coordinates": [25.0190874982373, 59.4878212489052] } },
    { "type": "Feature", "id": 363, "properties": { "store_name": "Araxes Estonia pst.", "address": "Estonia pst 6a, Ahtme, Kohtla-Järve", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.4213909693945, 59.3338829745353] } },
    { "type": "Feature", "id": 364, "properties": { "store_name": "Araxes Maleva", "address": "Maleva 25a, Ahtme, Kohtla-Järve", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.4196630693942, 59.3279674580513] } },
    { "type": "Feature", "id": 365, "properties": { "store_name": "Araxes Keskallee", "address": "Keskallee, Kohtla-Järve", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.2818929405623, 59.4011114391673] } },
    { "type": "Feature", "id": 366, "properties": { "store_name": "Araxes Pavlovi", "address": "Pavlovi 2a, Sillamäe ", "postcode": "", "city": "Sillamäe" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 367, "properties": { "store_name": "Araxes Viru'", "address": "Viru pst. 35, Sillamäe", "postcode": "", "city": "Sillamäe" }, "geometry": { "type": "Point", "coordinates": [27.7907941712451, 59.3910663762101] } },
    { "type": "Feature", "id": 368, "properties": { "store_name": "Araxes Puškini", "address": "Puškini 10, Narva ", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.1967823982318, 59.3740983313229] } },
    { "type": "Feature", "id": 369, "properties": { "store_name": "Araxes Võidu", "address": "Võidu 1, Narva", "postcode": "", "city": "Narva" }, "geometry": { "type": "Point", "coordinates": [28.1786454982321, 59.3789422891786] } },
    { "type": "Feature", "id": 370, "properties": { "store_name": "Laekvere Aldar Market", "address": "Laekvere alevik, Kesk tänav 12", "postcode": "", "city": "Vinni vald" }, "geometry": { "type": "Point", "coordinates": [26.5585474270524, 59.0675380606087] } },
    { "type": "Feature", "id": 371, "properties": { "store_name": "Lohusuu Aldar Market", "address": "Lohusuu alevik, Avinurme tee 2", "postcode": "", "city": "Mustvee vald" }, "geometry": { "type": "Point", "coordinates": [27.0485519712238, 58.9478436618339] } },
    { "type": "Feature", "id": 372, "properties": { "store_name": "Muuga Aldar Market", "address": "Muuga küla", "postcode": "", "city": "Vinni vald" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 373, "properties": { "store_name": "Pajusti Aldar Market", "address": "Pajusti alevik, Tartu maantee 13", "postcode": "", "city": "Vinni vald" }, "geometry": { "type": "Point", "coordinates": [26.4175303405566, 59.2828097059112] } },
    { "type": "Feature", "id": 374, "properties": { "store_name": "Purtse Aldar Market", "address": "Purtse küla", "postcode": "", "city": "Lüganuse vald" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 375, "properties": { "store_name": "Roela Aldar Market", "address": "Roela alevik, Tartu maantee 11", "postcode": "", "city": "Vinni vald" }, "geometry": { "type": "Point", "coordinates": [26.5858319270572, 59.1651451641024] } },
    { "type": "Feature", "id": 376, "properties": { "store_name": "Sonda Aldar Market", "address": "Sonda alevik, Uljaste tee 2", "postcode": "", "city": "Lüganuse vald" }, "geometry": { "type": "Point", "coordinates": [26.8451047270661, 59.3501222710195] } },
    { "type": "Feature", "id": 377, "properties": { "store_name": "Tudu Aldar Market", "address": "Tudu alevik, Rakvere maantee 7", "postcode": "", "city": "Vinni vald" }, "geometry": { "type": "Point", "coordinates": [26.8542871558933, 59.1801585212492] } },
    { "type": "Feature", "id": 378, "properties": { "store_name": "Tudulinna Aldar Market", "address": "Tudulinna alevik, Pikk tänav 8, 42202", "postcode": "", "city": "Alutaguse vald" }, "geometry": { "type": "Point", "coordinates": [27.0814543693801, 59.0360308812272] } },
    { "type": "Feature", "id": 379, "properties": { "store_name": "Viru-Jaagupi Aldar Market", "address": "Viru-Jaagupi alevik, Kooli tee 4, 46604", "postcode": "", "city": "Vinni vald" }, "geometry": { "type": "Point", "coordinates": [26.4749194540484, 59.2422421709892] } },
    { "type": "Feature", "id": 380, "properties": { "store_name": "Lilleoru Aldar Market", "address": "Tartu tänav 55, 44315", "postcode": "", "city": "Rakvere linn" }, "geometry": { "type": "Point", "coordinates": [26.3652741982296, 59.327897896497] } },
    { "type": "Feature", "id": 381, "properties": { "store_name": "Sadama Turg", "address": "Kesklinn, Kai tänav 6, 10111", "postcode": "", "city": "Tallinn, Harjumaa" }, "geometry": { "type": "Point", "coordinates": [24.7622599828936, 59.4443693215388] } },
    { "type": "Feature", "id": 382, "properties": { "store_name": "Ahtme CityAlko", "address": "Ahtme linnaosa, Puru tee 77, 31023", "postcode": "", "city": "Kohtla-Järve linn" }, "geometry": { "type": "Point", "coordinates": [27.420161955901, 59.3379099531519] } },
    { "type": "Feature", "id": 383, "properties": { "store_name": "Anne SuperAlko", "address": "Kaunase puiestee 81a, 50708", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.775823640513, 58.3735903969729] } },
    { "type": "Feature", "id": 384, "properties": { "store_name": "Arsenali CityAlko", "address": "Erika tänav 14, 10416", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7174037847417, 59.4513007322319] } },
    { "type": "Feature", "id": 385, "properties": { "store_name": "Haapsalu SuperAlko", "address": "Lihula maantee 3, 90507", "postcode": "", "city": "Haapsalu" }, "geometry": { "type": "Point", "coordinates": [23.5421702270463, 58.9389107861909] } },
    { "type": "Feature", "id": 386, "properties": { "store_name": "Kaubamajaka CityAlko", "address": "Papiniidu tänav 8, 80042", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.5510043135253, 58.3698838861578] } },
    { "type": "Feature", "id": 387, "properties": { "store_name": "Kiviõli CityAlko", "address": "Uus tänav 1, 43121", "postcode": "", "city": "Kiviõli" }, "geometry": { "type": "Point", "coordinates": [26.9772218559017, 59.3559018153108] } },
    { "type": "Feature", "id": 388, "properties": { "store_name": "Krooni CityAlko", "address": "Ferdinand Gustav Adoffi tänav 11, 44310", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [26.3640703982306, 59.3487631753978] } },
    { "type": "Feature", "id": 389, "properties": { "store_name": "Kuressaare SuperAlko", "address": "Pihtla tee 24, 93815", "postcode": "", "city": "Kuressaare" }, "geometry": { "type": "Point", "coordinates": [22.5029882405073, 58.2535527127428] } },
    { "type": "Feature", "id": 390, "properties": { "store_name": "Linnamäe SuperAlko", "address": "Linnamäe tee 17, 13912", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8679030270708, 59.4497947895254] } },
    { "type": "Feature", "id": 391, "properties": { "store_name": "Magdaleena SuperAlko", "address": "Pärnu maantee 107/109, 11312", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7406791568514, 59.416243085152] } },
    { "type": "Feature", "id": 392, "properties": { "store_name": "Magistrali CityAlko", "address": "Sõpruse puiestee 201/103, 13419", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6975477270686, 59.4014841219151] } },
    { "type": "Feature", "id": 393, "properties": { "store_name": "Mai SuperAlko", "address": "Metsa tänav 13, 80024", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.5409297423608, 58.3715548232417] } },
    { "type": "Feature", "id": 394, "properties": { "store_name": "Mustakivi CityAlko", "address": "Mustakivi tee 17, 13912", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.8627934828935, 59.4435251611307] } },
    { "type": "Feature", "id": 395, "properties": { "store_name": "Nõmme SuperAlko", "address": "Turu plats 5/7, 11611", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 396, "properties": { "store_name": "Pargi CityAlko", "address": "Jõhvi vald, Jõhvi linn, Pargi tänav 1, 41537", "postcode": "", "city": "Jõhv" }, "geometry": { "type": "Point", "coordinates": [27.4199335693956, 59.3561955569518] } },
    { "type": "Feature", "id": 397, "properties": { "store_name": "Pärnu Cityalko", "address": "Pärnu linn, Pärnu linn, Pikk tänav 12a, 80013", "postcode": "", "city": "Pärnu" }, "geometry": { "type": "Point", "coordinates": [24.5052807116783, 58.3871785861282] } },
    { "type": "Feature", "id": 398, "properties": { "store_name": "Raudalu CityAlko", "address": "Tallinn, Nõmme linnaosa, Viljandi maantee 41, 11218", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7587942982314, 59.3665159845333] } },
    { "type": "Feature", "id": 399, "properties": { "store_name": "Rüütli SuperAlko", "address": "Tartu linn, Tartu linn, Rüütli tänav 4, 51007", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7218882693487, 58.3808847039055] } },
    { "type": "Feature", "id": 400, "properties": { "store_name": "SadaMarketi CityAlko", "address": "Tallinn, Kesklinn, Kai tänav 5, 10111 ", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7568129982352, 59.4437646692017] } },
    { "type": "Feature", "id": 401, "properties": { "store_name": "Saue SuperAlko", "address": "Pärnasalu tn 31, Saue linn, Saue vald, Harju maakond, 76505", "postcode": "", "city": "Saue" }, "geometry": { "type": "Point", "coordinates": [24.5625834405586, 59.3242912542237] } },
    { "type": "Feature", "id": 402, "properties": { "store_name": "Sõbra CityAlko", "address": "Tartu linn, Tartu linn, Võru tänav 55f, 50111", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7194758981835, 58.3654696631293] } },
    { "type": "Feature", "id": 403, "properties": { "store_name": "Tabasalu CityAlko", "address": "Harku vald, Tabasalu alevik, Klooga mnt 6 a, 76901", "postcode": "", "city": "Tabasalu" }, "geometry": { "type": "Point", "coordinates": [24.5504074540575, 59.4300886303647] } },
    { "type": "Feature", "id": 404, "properties": { "store_name": "Tondi CityAlko", "address": "Tallinn, Mustamäe linnaosa, Tammsaare tee 62, 11316", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.714870711727, 59.4033570905357] } },
    { "type": "Feature", "id": 405, "properties": { "store_name": "Viimsi Cityalko", "address": "Viimsi vald, Haabneeme alevik, Sõpruse tee 9, 74001", "postcode": "", "city": "Viimsi" }, "geometry": { "type": "Point", "coordinates": [24.826866811732, 59.5067456449307] } },
    { "type": "Feature", "id": 406, "properties": { "store_name": "Viljandi SuperAlko", "address": "Viljandi maakond, Viljandi linn, Leola tänav 14a, 71012", "postcode": "", "city": "Viljandi" }, "geometry": { "type": "Point", "coordinates": [25.5917271116773, 58.3672456131564] } },
    { "type": "Feature", "id": 407, "properties": { "store_name": "Vironia CityAlko", "address": "Kohtla-Järve linn, Järve linnaosa, Järveküla tee 50, 30321", "postcode": "", "city": "Kohtla-Järve" }, "geometry": { "type": "Point", "coordinates": [27.2859822559038, 59.3975835853034] } },
    { "type": "Feature", "id": 408, "properties": { "store_name": "Võru CityAlko", "address": "Võru maakond, Võru linn, Kooli tänav 6, 65606", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [27.0083748116524, 57.8462341776318] } },
    { "type": "Feature", "id": 409, "properties": { "store_name": "Lootsi 6 SuperAlko C&C", "address": "Tallinn, Kesklinna linnaosa, Lootsi tänav 6, 10151", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7663351828935, 59.4414089029901] } },
    { "type": "Feature", "id": 410, "properties": { "store_name": "Lootsi 10 SuperAlko", "address": "Tallinn, Kesklinna linnaosa, Lootsi tänav 10, 10151", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7644810982351, 59.4419452923489] } },
    { "type": "Feature", "id": 411, "properties": { "store_name": "Alexela Abja-Paluoja ", "address": "Raudtee 2, Abja-Paluoja linn, Mulgi vald 69403", "postcode": "", "city": "Abja" }, "geometry": { "type": "Point", "coordinates": [25.3523917981718, 58.1230681057729] } },
    { "type": "Feature", "id": 412, "properties": { "store_name": "Alexela Aegviidu ", "address": "Kanarbiku 9, Aegviidu alev, Anija vald 74501", "postcode": "", "city": "Aegviidu" }, "geometry": { "type": "Point", "coordinates": [25.6156402693928, 59.2982890266671] } },
    { "type": "Feature", "id": 413, "properties": { "store_name": "Alexela Ehitajate tee ", "address": "Ehitajate tee 101, Tallinn 12915", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.6617325270691, 59.4119843361318] } },
    { "type": "Feature", "id": 414, "properties": { "store_name": "Alexela Halinga ", "address": "Halinga tankla, Loomse küla, Põhja-Pärnumaa vald 87222", "postcode": "", "city": "Halinga" }, "geometry": { "type": "Point", "coordinates": [24.5096486828528, 58.5959512766753] } },
    { "type": "Feature", "id": 415, "properties": { "store_name": "Alexela Harutee ", "address": "Harutee tankla, Ääsmäe küla, Saue vald 76402", "postcode": "", "city": "Ääsmäe" }, "geometry": { "type": "Point", "coordinates": [24.4907931405548, 59.2432561950227] } },
    { "type": "Feature", "id": 416, "properties": { "store_name": "Alexela Jõgeva 2 ", "address": "Risti, Õuna küla, Jõgeva vald 48439", "postcode": "", "city": "Õuna" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 417, "properties": { "store_name": "Alexela Jõhvi ", "address": "Rakvere 44, Jõhvi linn, Jõhvi vald 41532", "postcode": "", "city": "Rakvere" }, "geometry": { "type": "Point", "coordinates": [27.3921275559024, 59.3681202130331] } },
    { "type": "Feature", "id": 418, "properties": { "store_name": "Alexela Jüri ", "address": "Ringi tee 1, Jüri alevik, Rae vald 75301", "postcode": "", "city": "Jüri" }, "geometry": { "type": "Point", "coordinates": [24.8924884405602, 59.3566011342879] } },
    { "type": "Feature", "id": 419, "properties": { "store_name": "Alexela Kakumäe sadam ", "address": "Nooda tee 8, Tallinn 13516", "postcode": "", "city": "Kakumäe" }, "geometry": { "type": "Point", "coordinates": [24.6044991982355, 59.450594727497] } },
    { "type": "Feature", "id": 420, "properties": { "store_name": "Alexela Karksi-Nuia ", "address": "Pärnu mnt 2, Karksi-Nuia linn, Mulgi vald, Viljandi maakond, 69103", "postcode": "", "city": "Karksi-Nuia" }, "geometry": { "type": "Point", "coordinates": [25.5463154558419, 58.1054196487089] } },
    { "type": "Feature", "id": 421, "properties": { "store_name": "Alexela Keila ", "address": "Ülejõe tee 2, Keila 76603", "postcode": "", "city": "Keila" }, "geometry": { "type": "Point", "coordinates": [24.4360738270641, 59.3091293944492] } },
    { "type": "Feature", "id": 422, "properties": { "store_name": "Alexela Kose ", "address": "Ujula 10, Kose alevik, Kose vald 75101", "postcode": "", "city": "Kose" }, "geometry": { "type": "Point", "coordinates": [25.1587789405519, 59.1846962425041] } },
    { "type": "Feature", "id": 423, "properties": { "store_name": "Alexela Kuusalu ", "address": "Kuusalu tee 50, Kuusalu alevik, Kuusalu vald 74601", "postcode": "", "city": "Kuusalu" }, "geometry": { "type": "Point", "coordinates": [25.4352932559062, 59.4482885181131] } },
    { "type": "Feature", "id": 424, "properties": { "store_name": "Alexela Käsmu pood ", "address": "Merekooli 4, Käsmu küla, Haljala vald, 45601 Lääne-Virumaa", "postcode": "", "city": "Käsmu" }, "geometry": { "type": "Point", "coordinates": [25.9219029694075, 59.6046632078549] } },
    { "type": "Feature", "id": 425, "properties": { "store_name": "Alexela Laeva ", "address": "Tankla, Valmaotsa küla, Tartu vald 60601", "postcode": "", "city": "Valmaotsa" }, "geometry": { "type": "Point", "coordinates": [26.360423827024, 58.4714097058156] } },
    { "type": "Feature", "id": 426, "properties": { "store_name": "Alexela Loo ", "address": "Niidu tee 2, Loo alevik, Jõelähtme vald 74201", "postcode": "", "city": "Loo" }, "geometry": { "type": "Point", "coordinates": [24.9294640693998, 59.4431288174779] } },
    { "type": "Feature", "id": 427, "properties": { "store_name": "Alexela Orissaare ", "address": "Kuivastu mnt 3A, Orissaare alevik, Saaremaa vald 94602", "postcode": "", "city": "Orissaare" }, "geometry": { "type": "Point", "coordinates": [23.0703913981926, 58.5581942395459] } },
    { "type": "Feature", "id": 428, "properties": { "store_name": "Alexela Otepää ", "address": "Tartu mnt 24, Otepää linn, Otepää vald 67404", "postcode": "", "city": "Otepää" }, "geometry": { "type": "Point", "coordinates": [26.4980158828274, 58.0629258863476] } },
    { "type": "Feature", "id": 429, "properties": { "store_name": "Alexela Paiaristi ", "address": "Paiaristi, Imavere küla, Järva vald 72401", "postcode": "", "city": "Imavere" }, "geometry": { "type": "Point", "coordinates": [25.7687646270369, 58.7447836148619] } },
    { "type": "Feature", "id": 430, "properties": { "store_name": "Alexela Paldiski ", "address": "Tallinna mnt 20, Paldiski linn, Lääne-Harju vald 76806", "postcode": "", "city": "Paldiski" }, "geometry": { "type": "Point", "coordinates": [24.1052283982301, 59.3394059511713] } },
    { "type": "Feature", "id": 431, "properties": { "store_name": "Alexela Peterburi tee ", "address": "Peterburi tee 77, Tallinn 11415", "postcode": "", "city": "Peterburi" }, "geometry": { "type": "Point", "coordinates": [24.8495961693992, 59.4320596192326] } },
    { "type": "Feature", "id": 432, "properties": { "store_name": "Alexela Põltsamaa ", "address": "Jõgeva mnt 25, Põltsamaa linn, Põltsamaa vald 48105", "postcode": "", "city": "Põltsamaa" }, "geometry": { "type": "Point", "coordinates": [25.9907230847039, 58.6636652169267] } },
    { "type": "Feature", "id": 433, "properties": { "store_name": "Alexela Raudalu ", "address": "Viljandi mnt 36a, Tallinn 11218", "postcode": "", "city": "Raudalu" }, "geometry": { "type": "Point", "coordinates": [24.763848055902, 59.3622355943797] } },
    { "type": "Feature", "id": 434, "properties": { "store_name": "Alexela Riiamäe ", "address": "Era 2A, Tartu 51010", "postcode": "", "city": "Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7212674693483, 58.37184690142] } },
    { "type": "Feature", "id": 435, "properties": { "store_name": "Alexela Riisipere ", "address": "Mäekalda, Vilumäe küla, Saue vald 76213", "postcode": "", "city": "Mäekalda" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 436, "properties": { "store_name": "Alexela Roseni Gurmee ", "address": "Roseni 11, 10111 Tallinn", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7554863270704, 59.4393478977926] } },
    { "type": "Feature", "id": 437, "properties": { "store_name": "Alexela Saku ", "address": "Männiku tee 146, Tammemäe küla, Saku vald 75509", "postcode": "", "city": "Saku" }, "geometry": { "type": "Point", "coordinates": [24.6991974693947, 59.3367889829293] } },
    { "type": "Feature", "id": 438, "properties": { "store_name": "Alexela Saue ", "address": "Sooja 2A, Saue linn, Saue vald 76505", "postcode": "", "city": "Saue" }, "geometry": { "type": "Point", "coordinates": [24.5670637117231, 59.3228683497652] } },
    { "type": "Feature", "id": 439, "properties": { "store_name": "Alexela Suure-Jaani", "address": "Oja 2, Suure-Jaani linn, Põhja-Sakala vald 71503", "postcode": "", "city": "Suure-Jaani" }, "geometry": { "type": "Point", "coordinates": [25.4642005981912, 58.5289515944004] } },
    { "type": "Feature", "id": 440, "properties": { "store_name": "Alexela Sõle ", "address": "Sõle 27A, Tallinn 10615", "postcode": "", "city": "Tallinn" }, "geometry": { "type": "Point", "coordinates": [24.7079715559057, 59.4385191129859] } },
    { "type": "Feature", "id": 441, "properties": { "store_name": "Alexela Sõmeru", "address": "Puiestee 22, Sõmeru alevik, Rakvere vald 44202", "postcode": "", "city": "Sõmeru" }, "geometry": { "type": "Point", "coordinates": [26.4332307693959, 59.365277818914] } },
    { "type": "Feature", "id": 442, "properties": { "store_name": "Alexela Tapa ", "address": "Pikk tn 16, Tapa, 45106", "postcode": "", "city": "Tapa" }, "geometry": { "type": "Point", "coordinates": [25.963354269391, 59.2613019192862] } },
    { "type": "Feature", "id": 443, "properties": { "store_name": "Alexela Tiskre ", "address": "Liiva tee 59, Tiskre küla, Harku vald, Harju maakond, 76916", "postcode": "", "city": "Tiskre" }, "geometry": { "type": "Point", "coordinates": [24.5650547559054, 59.4322530251311] } },
    { "type": "Feature", "id": 444, "properties": { "store_name": "Alexela Tõdva", "address": "Tõdva tankla, Tõdva küla, Saku vald, Harju maakond", "postcode": "", "city": "Tõdva" }, "geometry": { "type": "Point", "coordinates": [24.7405457405561, 59.2701395286569] } },
    { "type": "Feature", "id": 445, "properties": { "store_name": "Alexela Tõrva ", "address": "Viljandi tn 26 Tõrva, Tõrva vald Valgamaa 68604", "postcode": "", "city": "Tõrva" }, "geometry": { "type": "Point", "coordinates": [25.9156665270017, 58.006129810147] } },
    { "type": "Feature", "id": 446, "properties": { "store_name": "Alexela Virtsu ", "address": "Vanalinna 2, Virtsu alevik, Lääneranna vald 90101", "postcode": "", "city": "Virtsu" }, "geometry": { "type": "Point", "coordinates": [23.5278048828524, 58.5877939581698] } },
    { "type": "Feature", "id": 447, "properties": { "store_name": "Alexela Viti ", "address": "Viti tankla, Viti küla, Harku vald 76910", "postcode": "", "city": "Viti" }, "geometry": { "type": "Point", "coordinates": [24.3808394693998, 59.4456714735837] } },
    { "type": "Feature", "id": 448, "properties": { "store_name": "Alexela Võru ", "address": "Kose tee 6, Võru 65522", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [27.0209331116513, 57.8209247471354] } },
    { "type": "Feature", "id": 449, "properties": { "store_name": "Alexela Vändra ", "address": "J.V.Jannseni 4, Allikõnnu küla, Põhja-Pärnumaa vald 87604", "postcode": "", "city": "Vändra" }, "geometry": { "type": "Point", "coordinates": [25.0264221116911, 58.6554650720333] } },
    { "type": "Feature", "id": 450, "properties": { "store_name": "Alexela Väo", "address": "Tooma 1, Tallinn 11415", "postcode": "", "city": "Väo" }, "geometry": { "type": "Point", "coordinates": [24.9008935405642, 59.4408326946807] } },
    { "type": "Feature", "id": 451, "properties": { "store_name": "Tartu Terminal AS, Kärkna ", "address": "Kärkna küla", "postcode": "", "city": "Tartu vald" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 452, "properties": { "store_name": "Tartu Terminal AS, Tõrvandi ", "address": "Kauba tee 4", "postcode": "", "city": "Ülenurme vald" }, "geometry": { "type": "Point", "coordinates": [26.7131957270167, 58.3218040236117] } },
    { "type": "Feature", "id": 453, "properties": { "store_name": "Tartu Terminal AS, Põlva ", "address": "Jaama 71", "postcode": "", "city": "Põlva linn" }, "geometry": { "type": "Point", "coordinates": [27.0828916251569, 58.0709904900429] } },
    { "type": "Feature", "id": 454, "properties": { "store_name": "Tartu Terminal AS, Emajõe ", "address": "Rebase 18 A", "postcode": "", "city": "Tartu linn" }, "geometry": { "type": "Point", "coordinates": [26.7452792558544, 58.3686993291594] } },
    { "type": "Feature", "id": 455, "properties": { "store_name": "Tartu Terminal AS, Kuningamäe ", "address": "Kingu tee 2", "postcode": "", "city": "Põltsamaa vald" }, "geometry": { "type": "Point", "coordinates": [25.9499643405263, 58.6524694587504] } },
    { "type": "Feature", "id": 456, "properties": { "store_name": "Tartu Terminal AS, Raadi ", "address": "Nõlvakaare 2", "postcode": "", "city": "Tila küla Tartu vald" }, "geometry": { "type": "Point", "coordinates": [26.7496381558562, 58.4078226275436] } },
    { "type": "Feature", "id": 457, "properties": { "store_name": "Tartu Terminal AS, Jüri ", "address": "Bensiimijaama tee, Lehmja küla", "postcode": "", "city": "Rae vald, Harjumaa" }, "geometry": { "type": "Point", "coordinates": [24.8677037847373, 59.3581229725007] } },
    { "type": "Feature", "id": 458, "properties": { "store_name": "Tartu Terminal AS, Nõo ", "address": "Raudtee 2a", "postcode": "", "city": "Nõo vald, Tartumaa" }, "geometry": { "type": "Point", "coordinates": [26.5299840405086, 58.2825840265676] } },
    { "type": "Feature", "id": 459, "properties": { "store_name": "Tartu Terminal AS, Kivilinna ", "address": "Jaama 173 A", "postcode": "", "city": "Tartu linn" }, "geometry": { "type": "Point", "coordinates": [26.7661969846903, 58.3795700065239] } },
    { "type": "Feature", "id": 460, "properties": { "store_name": "Tartu Terminal AS, Kiiu ", "address": "Vana -Narva mnt 7, Kiiu alevik", "postcode": "", "city": "Kuusalu vald, Harjumaa" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 461, "properties": { "store_name": "Tartu Terminal AS, GMP, Tartu ", "address": "Küüni 5", "postcode": "", "city": "Tartu linn" }, "geometry": { "type": "Point", "coordinates": [26.7232625981841, 58.3794766318677] } },
    { "type": "Feature", "id": 462, "properties": { "store_name": "Tartu Terminal AS, Saverna ", "address": "Teeristi, Saverna küla, Kanepi vald", "postcode": "", "city": "Põlva maakond" }, "geometry": { "type": "Point", "coordinates": [26.7308366981692, 58.0669716290584] } },
    { "type": "Feature", "id": 463, "properties": { "store_name": "Tartu Terminal AS, Võru ", "address": "Räpina mnt 11", "postcode": "", "city": "Võru" }, "geometry": { "type": "Point", "coordinates": [27.0184349981586, 57.8442344028052] } },
    { "type": "Feature", "id": 464, "properties": { "store_name": "Tartu Terminal AS, Narva Jõesuu ", "address": "Poska 26", "postcode": "", "city": "Narva- Jõesuu" }, "geometry": { "type": "Point", "coordinates": [28.0429665405653, 59.4631953527911] } },
    { "type": "Feature", "id": 465, "properties": { "store_name": "Tartu Terminal AS,Rõngu ", "address": "Võru mnt 1, Rõngu alevik", "postcode": "", "city": "Elva vald, Tartumaa" }, "geometry": { "type": "Point", "coordinates": [26.248506440502, 58.142237246604] } },
    { "type": "Feature", "id": 466, "properties": { "store_name": "Tartu Terminal AS, Mustakivi ", "address": "Osmussaare 15", "postcode": "", "city": "Tallinn, Harjumaa" }, "geometry": { "type": "Point", "coordinates": [24.8703267559056, 59.4372513359064] } },
    { "type": "Feature", "id": 467, "properties": { "store_name": "Tartu Terminal AS, Raatuse ", "address": "Raatuse 21", "postcode": "", "city": "50603 Tartu" }, "geometry": { "type": "Point", "coordinates": [26.7308413270196, 58.3818637772141] } },
    { "type": "Feature", "id": 468, "properties": { "store_name": "Tartu Terminal AS, Väike-Maarja ", "address": "Ebavere küla", "postcode": "", "city": "Väike-Maarja vald, Ebavere 46209" }, "geometry": { "type": "Point", "coordinates": [,] } },
    { "type": "Feature", "id": 469, "properties": { "store_name": "Tartu Terminal AS, Puhja ", "address": "Hommiku tn 2, Puhja alevik", "postcode": "", "city": "Elva vald 61301" }, "geometry": { "type": "Point", "coordinates": [26.3188700981823, 58.339746226398] } },
    { "type": "Feature", "id": 470, "properties": { "store_name": "Tartu Terminal AS, Selja ", "address": "Rakvere mnt 18", "postcode": "", "city": "Selja, Pärnumaa" }, "geometry": { "type": "Point", "coordinates": [24.8200752270261, 58.5184625042376] } },
  ]
};

export default stores;