import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";

import veloBgHeroMobile from "../assets/VeloPage/hero.jpg";
import veloBgHeroDesktop from "../assets/VeloPage/desktop/hero.jpg";
import veloLogoSvg from "../assets/VeloPage/velo-logo.svg";
import veloHeroPack from "../assets/VeloPage/desktop/velo-hero.png";
import veloHeroPackMobile from "../assets/VeloPage/velo-hero.png";
import polarMintPack from "../assets/VeloPage/polar-mint.png";
import packVelo1 from "../assets/VeloPage/product-velo-1.png";
import packVelo2 from "../assets/VeloPage/product-velo-2.png";
import packVelo3 from "../assets/VeloPage/product-velo-3.png";
import packVelo4 from "../assets/VeloPage/product-velo-4.png";
import packVelo5 from "../assets/VeloPage/product-velo-5.png";
import packVelo6 from "../assets/VeloPage/product-velo-6.png";

import tutorialSvg1 from "../assets/VeloPage/tutorial-icon-1.svg";
import tutorialSvg2 from "../assets/VeloPage/tutorial-icon-2.svg";
import tutorialSvg3 from "../assets/VeloPage/tutorial-icon-3.svg";
import tutorialSvg4 from "../assets/VeloPage/tutorial-icon-4.svg";

import roadMapDestkop from "../assets/VeloPage/desktop/road.png";
import roadMapMobile from "../assets/VeloPage/road.png";

import findSectionImage from "../assets/VeloPage/desktop/section-image.jpg";
import findSectionImageMobile from "../assets/VeloPage/section-image.jpg";
import smokeLogo from "../assets/logo/smw-logo.png";
import { useModalContext } from "../context/ModalContext";
import setEvent from '../hook/setEvent';
import { useParams } from "react-router-dom";


const PrivacyPolicyPage = () => {
  const { error, handleError, handleCloseAgeGate } = useModalContext();
  const { lang } = useParams();

  useEffect(() => {
    handleCloseAgeGate();
    setEvent({
      'UserId': null,
      'PageType': 'privacy policy',
      'SystemType': null,
      'Country': lang,
      'LoggedInStatus': 'logged out',
      'SiteSection': 'PrivacyPolicy',
      'ServerSideTampstamp': Date.now()
    });
  }, [])

  return (
    <Layout footerType="black">
      <section className="policyPage container">
        <div
          data-enable-parallax={0}
          data-parallax-speed="0.5"
          data-background-images="{}"
          data-element="inner"
          style={{
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column",
            backgroundPosition: "left top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
            borderStyle: "none",
            borderWidth: "1px",
            borderRadius: "0px",
            margin: "0px",
            padding: "20px",
          }}
        >
          <h2
            data-content-type="heading"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
            }}
          >
            Nasza Polityka Prywatności w pigułce
          </h2>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>1. KIM JESTEŚMY?</strong> W większości przypadków
              opisanych poniżej British American Tobacco Polska Trading sp. z
              o.o. (BAT) oraz CHIC spółka z ograniczoną odpowiedzialnością sp.k.
              (CHIC) przetwarzają Twoje dane osobowe wspólnie, jako
              współadministratorzy. Oznacza to, że wspólnie odpowiadają za ich
              ochronę i zgodne z prawem wykorzystanie. W pewnych przypadkach BAT
              i CHIC przetwarzają Twoje dane samodzielnie. Wszystkie te
              przypadki zostały opisane poniżej. &nbsp;
              <a href="#pkt2">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  Dowiedz się więcej (pkt 2).
                </span>
              </a>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>2. DLACZEGO POTRZEBUJEMY TWOICH DANYCH?</strong> Twoje
              dane są nam niezbędne abyśmy mogli, zgodnie z Twoim życzeniem,
              m.in. informować Cię o naszych ofertach, promocjach, nowościach i
              konkursach, zaprezentować Ci nasze produkty podczas osobistego
              spotkania z naszym Ambasadorem, zarządzać Twoim członkostwem w
              naszych programach lojalnościowych, zrealizować Twoje zamówienie w
              sklepie Glo, a także aby poznać Twoją opinię na temat użytkowania
              naszych produktów. &nbsp;
              <a href="pkt2">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  Dowiedz się więcej (pkt 2).
                </span>
              </a>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>3. DLACZEGO KORZYSTAMY Z TWOICH DANYCH?</strong> Posiadamy
              ważną podstawę prawną pozwalającą nam na wykorzystywanie Twoich
              danych, aczkolwiek możemy to robić jedynie w ściśle określonych
              celach. Najczęściej podstawą wykorzystania Twoich danych jest
              Twoja zgoda, którą wyraziłeś/aś na naszych stronach internetowych,
              rejestrując się w naszych programach lojalnościowych albo podczas
              spotkania z naszymi przedstawicielami . W innych przypadkach
              wykorzystanie Twoich danych jest niezbędne do zrealizowania
              zawartej z Tobą umowy. &nbsp;
              <a href="#pkt3">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  Dowiedz się więcej (pkt 3).
                </span>
              </a>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>4. Z KIM DZIELIMY SIĘ TWOIMI DANYMI?</strong> W pewnych
              sytuacjach możemy przekazywać pewne informacje na Twój temat innym
              podmiotom należącym do grupy British American Tobacco, jak również
              podmiotom świadczącym na naszą rzecz niezbędne usługi (np.
              informatyczne). Jeśli wyraziłeś/aś na to zgodę, Twój adres e-mail
              będziemy mogli także udostępnić (w formie zaszyfrowanej)
              operatorom portali społecznościowych. Niektórzy spośród odbiorców
              Twoich danych mogą być zlokalizowani poza Unią Europejską lub
              Europejskim Obszarem Gospodarczym. &nbsp;
              <a href="#pkt4">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  Dowiedz się więcej (pkt 7 i 8).
                </span>
              </a>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>5. TWOJE PRAWA?</strong> Masz prawo dostępu, poprawiania
              lub usunięcia Twoich danych osobowych. Jeśli wyraziłeś/aś zgodę na
              przetwarzanie Twoich danych, masz prawo do jej wycofania w każdym
              czasie. W pewnych sytuacjach będziesz miał/a także prawo do,
              m.in., przenoszenia Twoich danych osobowych oraz sprzeciwu wobec
              ich przetwarzania. &nbsp;
              <a href="#pkt4">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  Dowiedz się więcej (pkt 4).
                </span>
              </a>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>
                Zachęcamy do zapoznania się z pełną treścią naszej Polityki
                Prywatności, dzięki czemu dowiesz się więcej na temat zasad
                postępowania z Twoimi danymi osobowymi oraz ich ochrony.
              </strong>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <h2>
              <span style={{ color: "#0000ff" }}>Polityka Prywatności</span>
            </h2>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>
                Szczegółowe informacje dotyczące przetwarzania danych osobowych
                konsumentów BAT oraz CHIC
              </strong>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>Szanowny Kliencie</strong>
            </p>
            <p>
              Dziękujemy Ci za zainteresowanie ofertą grupy British American
              Tobacco. Prosimy o uważne zapoznanie się z poniższymi podstawowymi
              informacjami dotyczącymi zasad ochrony Twoich danych osobowych,
              którymi zdecydowałeś/aś się z nami podzielić. Poniżej wyjaśniamy w
              jaki sposób będziemy wykorzystywać informacje na Twój temat, kto
              będzie odpowiedzialny za ich ochronę, a także opisujemy prawa,
              które przysługują Ci w związku z przekazaniem nam tych informacji.
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              W niniejszej Polityce Prywatności odnajdziesz &nbsp;
              <strong>wszystkie istotne informacje</strong> dotyczące zasad
              wykorzystywania przez nas danych osobowych naszych klientów
              (konsumentów), jak również ich ochrony.
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>
                Zależy nam na zapewnieniu pełnej przejrzystości naszych działań,
              </strong>
              &nbsp; przede wszystkim jeśli chodzi o zakres wykorzystania przez
              nas Twoich danych osobowych, wyjaśnienie Ci wszelkich skutków,
              jakie mogą w związku z tym dla Ciebie wyniknąć, a także
              przedstawienie Ci Twoich praw. W związku z tym:
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              • umożliwiamy Ci &nbsp;
              <strong>
                nieograniczony dostęp do niniejszej Polityki Prywatności,
              </strong>
              &nbsp; dzięki czemu w każdej chwili będziesz miał/a możliwość
              odszukania ważnych dla Ciebie informacji, a także,
            </p>
            <p>
              • zapewniamy Ci możliwość uzyskania &nbsp;
              <strong>
                wszelkich dodatkowych informacji na temat postępowania z Twoimi
                danymi osobowymi,
              </strong>
              &nbsp; które możesz uzyskać kontaktując się z nami (dane
              kontaktowe podano w &nbsp;
              <a href="#pktpon">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  pkt. 1 III poniżej).
                </span>
              </a>
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <h2>
              <span style={{ color: "#0000ff" }}>
                Ważne definicje i objaśnienia
              </span>
            </h2>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <strong>
                Wyjaśnienie skrótów i pojęć używanych w dalszej części Polityki
                Prywatności
              </strong>
            </p>
            <p>
              Zanim przejdziesz dalej, prosimy Cię o zapoznanie się z poniższymi
              objaśnieniami. Pomoże Ci to właściwie zrozumieć istotne dla Ciebie
              treści, które chcemy Ci przekazać w tym dokumencie.
            </p>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <table
              style={{ height: "348px", width: "991px" }}
              border={1}
              frame="VOID"
              rules="ROWS"
              cellSpacing={0}
              cellPadding={7}
            >
              <tbody>
                <tr style={{ height: "31px" }} valign="TOP">
                  <td style={{ height: "31px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>BAT</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "31px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            British American Tobacco Polska Trading spółka z
                            ograniczoną odpowiedzialnością z siedzibą w
                            Warszawie przy ul. Krakowiaków 48
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "31px" }} valign="TOP">
                  <td style={{ height: "31px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>CHIC</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "31px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            CHIC spółka z ograniczoną odpowiedzialnością spółka
                            komandytowa z siedzibą w Ostrzeszowie przy al.
                            Wojska Polskiego 23C
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "72px" }} valign="TOP">
                  <td style={{ height: "72px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Grupa BAT /<br />
                              Grupa British American Tobacco
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "72px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Przedsiębiorstwa (spółki) wchodzące w skład
                            międzynarodowej grupy British American Tobacco
                            oferującej tradycyjne wyroby tytoniowe (m.in. Pall
                            Mall, Vogue, Lucky Strike), papierosy elektroniczne
                            i wyroby powiązane (Vype, Volish, Milp) oraz
                            produkty do podgrzewania tytoniu – rekwizyty
                            tytoniowe marki glo i nowatorskie wyroby tytoniowe
                            marki neo™. BAT oraz CHIC są członkami grupy BAT.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "31px" }} valign="TOP">
                  <td style={{ height: "31px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Dane osobowe</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "31px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Wszelkie informacje o zidentyfikowanej lub możliwej
                            do zidentyfikowania osobie fizycznej
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "51px" }} valign="TOP">
                  <td style={{ height: "51px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Administrator danych osobowych</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "51px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Osoba fizyczna lub prawna, organ publiczny,
                            jednostka lub inny podmiot, który samodzielnie lub
                            wspólnie z innymi ustala cele i sposoby
                            przetwarzania danych osobowych
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "51px" }} valign="TOP">
                  <td style={{ height: "51px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Współadministratorzy danych osobowych
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "51px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Co najmniej dwóch administratorów wspólnie
                            ustalających cele i sposoby przetwarzania danych
                            osobowych, na podstawie zawartego porozumienia
                            (umowy)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "51px" }} valign="TOP">
                  <td style={{ height: "51px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>RODO</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "51px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Rozporządzenie Parlamentu Europejskiego i Rady (UE)
                            2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                            ochrony osób fizycznych w związku z przetwarzaniem
                            danych osobowych i w sprawie swobodnego przepływu
                            takich danych oraz uchylenia dyrektywy 95/46/WE
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "30px" }} valign="TOP">
                  <td style={{ height: "30px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Program ESC</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "30px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Program lojalnościowy „eSmoking Club” edycja 3.0,
                            której organizatorem jest CHIC oraz, którego zasady
                            zostały określone w Regulaminie eSmoking Club,
                            edycja 3.0, który jest dostępny m.in. na stronie
                            &nbsp;
                          </span>
                        </span>
                      </span>
                      <a
                        title="www.esmokingclub.pl."
                        href="http://www.esmokingclub.pl."
                      >
                        <span style={{ textDecoration: "underline" }}>
                          <span
                            style={{
                              color: "#0000ff",
                              textDecoration: "underline",
                            }}
                          >
                            http://www.esmokingclub.pl.
                          </span>
                        </span>
                      </a>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "30px" }} valign="TOP">
                  <td style={{ height: "30px", width: "174px" }}>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Sklep Glo</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td style={{ height: "30px", width: "817px" }}>
                    <p align="JUSTIFY">
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Sklep internetowy dostępny pod adresem &nbsp;
                          </span>
                        </span>
                      </span>
                      <a href="http://discoverglo.com/pl/pl">
                        <span style={{ textDecoration: "underline" }}>
                          <span
                            style={{
                              color: "#0000ff",
                              textDecoration: "underline",
                            }}
                          >
                            http://discoverglo.com/pl/p
                          </span>
                        </span>
                        l
                      </a>
                      <br />
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            (dawniej pod adresem: www.sklep.glo.pl)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            data-content-type="text"
            data-appearance="default"
            data-element="main"
            style={{
              borderStyle: "none",
              borderWidth: "1px",
              borderRadius: "0px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <p>
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      1. Kim jesteśmy? W jaki sposób możesz się z nami
                      skontaktować?
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#595959" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Administratorzy Twoich danych osobowych i ich dane
                      kontaktowe
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <ol type="I">
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#262626" }}>
                    <span style={{ fontFamily: "Helvetica, serif" }}>
                      <span style={{ fontSize: "small" }}>
                        <strong>
                          BAT i CHIC jako współadministratorzy danych osobowych
                        </strong>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    W większości przypadków opisanych w tej Polityce Prywatności
                    współadministratorami Twoich danych osobowych są:
                  </span>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>
                          British American Tobacco Polska Trading sp. z o.o. z
                          siedzibą w Warszawie &nbsp;
                        </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        (dalej także jako: "
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>BAT</strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>")</span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>oraz</span>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>
                          CHIC spółka z ograniczoną odpowiedzialnością sp.k. z
                          siedzibą w Ostrzeszowie
                        </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp; (dalej także jako: "
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>CHIC</strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>")</span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Współadministrowanie danymi oznacza, że BAT oraz CHIC
                      wspólnie ustalają cele oraz sposoby przetwarzania
                      informacji na Twój temat (ich przechowywania,
                      wykorzystywania, udostępniania itp.).
                    </strong>
                  </span>
                </span>
              </span>
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    &nbsp; Oznacza to także, że BAT oraz CHIC wspólnie ponoszą
                    odpowiedzialność za Twoje dane. Decyzja o wspólnym
                    administrowaniu danymi osobowymi naszych klientów wynika z
                    faktu, że BAT oraz CHIC należą do jednej grupy wzajemnie
                    powiązanych przedsiębiorstw i&nbsp;w&nbsp;związku z tym
                    wspólnie planują i&nbsp;realizują wiele przedsięwzięć, a
                    także tworzą wspólną ofertę produktową. Wspólne
                    administrowanie danymi pozwala nam zwiększyć efektywność
                    naszych działań, a naszym klientom gwarantuje większą
                    przejrzystość naszych działań.
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    Więcej informacji na temat współadministrowania danymi
                    osobowymi przez BAT oraz CHIC znajdziesz &nbsp;
                  </span>
                </span>
              </span>
              <a href="#Wsp%C3%B3%C5%82administrowanie">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  w pkt. 5 poniżej
                </span>
              </a>
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>.</span>
                </span>
              </span>
            </p>
            <ol start={2} type="I">
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>
                          BAT i CHIC jako niezależni administratorzy danych
                          osobowych
                        </strong>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    W niektórych przypadkach (wskazanych &nbsp;
                  </span>
                </span>
              </span>
              <a href="#Kto_i_w_jakim_celu_przetwarza">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  w tabeli w pkt. 2 poniżej
                </span>
              </a>
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    ) administratorem odpowiedzialnym za Twoje dane osobowe
                    będzie tylko jeden podmiot - BAT albo CHIC. W tych
                    sytuacjach BAT i CHIC samodzielnie decydują o&nbsp;sposobach
                    i celach przetwarzania Twoich danych. Przykładami sytuacji,
                    w których BAT i CHIC działają samodzielnie jest np.
                    realizacja zamówienia w sklepie sklep.glo.pl lub obsługa
                    Twojego uczestnictwa w programach lojalnościowych
                    organizowanych przez BAT. &nbsp;
                  </span>
                </span>
              </span>
            </p>
            <ol start={3} type="I">
              <li>
                <p align="JUSTIFY">
                  <a id="pktpon" />
                  &nbsp;
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>Nasze dane kontaktowe</strong>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <ul>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        British American Tobacco Polska Trading sp. z o.o.&nbsp;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    Krakowiaków 48, 02-255 Warszawa (z dopiskiem: "Dane
                    osobowe")
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <a id="_Hlk15392283" name="_Hlk15392283" />
              &nbsp;
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    &nbsp; dedykowany adres e-mail: &nbsp;
                  </span>
                </span>
              </span>
              <a href="mailto:daneosobowe@bat.com.pl">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  daneosobowe@bat.com.pl
                </span>
              </a>
            </p>
            <ul>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        CHIC spółka z ograniczona odpowiedzialnością sp.k.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    al. Wojska Polskiego 23C, 63-500 Ostrzeszów (z dopiskiem:
                    "Dane osobowe")
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    dedykowany adres e-mail: &nbsp;
                  </span>
                </span>
              </span>
              <a href="mailto:daneosobowe@bat.com.pl">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  daneosobowe@bat.com.pl
                </span>
              </a>
            </p>
            <p align="JUSTIFY">
              <a id="_Hlk14434715" name="_Hlk14434715" />
              &nbsp;
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Aby skontaktować się z nami w celu realizacji Twoich praw,
                      wyrażenia albo cofnięcia zgód oraz wszelkich innych
                      kwestii związanych z ochroną danych osobowych
                      uruchomiliśmy specjalny wspólny adres e-mail: &nbsp;
                      <a href="mailto:daneosobowe@bat.com.pl">
                        <span style={{ textDecoration: "underline" }}>
                          daneosobowe@bat.com.pl
                        </span>
                      </a>
                      . Na ten adres możesz kierować swoje zapytania lub wnioski
                      we wszystkich sytuacjach opisanych w niniejszej Polityce
                      Prywatności, niezależnie od tego kto w danej sytuacji
                      odpowiada za Twoje dane - BAT, CHIC czy oba te podmioty
                      wspólnie.
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <ol start={4} type="I">
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#262626" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>Inspektor Ochrony Danych Osobowych</strong>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    W BAT oraz CHIC powołaliśmy inspektora ochrony danych, z
                    którym można kontaktować się we wszystkich sprawach
                    dotyczących przetwarzania danych osobowych oraz korzystania
                    z praw z tym związanych. Kontakt z inspektorem ochrony
                    danych może nastąpić poprzez:
                  </span>
                </span>
              </span>
            </p>
            <ul>
              <li>e-mail:</li>
            </ul>
            <p style={{ fontSize: "small" }}>
              dla BAT: &nbsp;
              <a href="mailto:iod@bat.com">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  iod@bat.com,
                </span>
              </a>
            </p>
            <p style={{ fontSize: "small" }}>
              dla CHIC: &nbsp;
              <a href="mailto:iod@esmokingworld.com">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  iod@esmokingworld.com
                </span>
              </a>
            </p>
            <ul>
              <li>
                pisemnie: na adresy wskazane w punkcie III powyżej, z dopiskiem
                „Inspektor Ochrony Danych”
              </li>
            </ul>
            <p>
              <a id="pkt2" />

              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      2. Kto i w jakim celu przetwarza Twoje dane osobowe?
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#474747" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Wyjaśnienie celów przetwarzania i wskazanie
                      administratorów Twoich danych osobowych
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    Poniżej przedstawiamy informację na temat różnych celów
                    przetwarzania danych osobowych w związku z naszą
                    działalnością. Zwracamy uwagę, że nie wszystkie cele opisane
                    poniżej muszą mieć zastosowanie do Twojego przypadku, a
                    będzie to zależało od wyrażonych przez Ciebie zgód oraz od
                    podjętych przez Ciebie aktywności np. na naszych stronach
                    internetowych. &nbsp;
                  </span>
                </span>
              </span>
            </p>
            <table border={1} cellSpacing={0} cellPadding={7}>
              <tbody>
                <tr>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span>
                        <span style={{ fontSize: "small" }}>
                          <strong>Cel przetwarzania</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span>
                        <span style={{ fontSize: "small" }}>
                          <strong>Dodatkowe informacje</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span>
                        <span style={{ fontSize: "small" }}>
                          <strong>Administrator danych</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Prowadzenie niepublicznych działań marketingowych
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Jeśli wyraziłeś/aś zainteresowanie otrzymywaniem od
                            nas aktualnych informacji na temat naszej oferty,
                            poprosiliśmy Cię o podanie w tym celu niektórych
                            Twoich danych. Potrzebujemy ich, aby móc przekazywać
                            Ci informacje i materiały dotyczące naszych
                            produktów, aktualnych ofert, nowości, konkursów,
                            niepublicznych promocji, wydarzeń i innych
                            niepublicznych działań związanych z naszą
                            działalnością. Wspomniane informacje i materiały
                            mogą dotyczyć zarówno wspólnej oferty bądź inicjatyw
                            BAT oraz CHIC, jak również każdego z nich osobno.
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Według dokonanego przez Ciebie wyboru, mogłeś/aś
                            zdecydować się na otrzymywanie takich informacji lub
                            materiałów za pośrednictwem wszystkich lub tylko
                            niektórych dostępnych sposobów (kanałów)
                            komunikacji. Poniżej znajdziesz informację na temat
                            dostępnych możliwości:
                          </span>
                        </span>
                      </span>
                    </p>
                    <ol type="i">
                      <li>
                        <p align="JUSTIFY">
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                jeśli zgodziłeś/aś się na otrzymywanie{"{"}" "
                                {"}"}
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                <strong>wiadomości elektronicznych</strong>
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                , będziemy przesyłać Ci interesujące Cię
                                materiały i informacje za pomocą poczty
                                elektronicznej oraz SMS-ów;
                              </span>
                            </span>
                          </span>
                        </p>
                      </li>
                      <li>
                        <p align="JUSTIFY">
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                jeśli zgodziłeś/aś się na &nbsp;
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                <strong>
                                  kontakt za pośrednictwem telefonu
                                </strong>
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                , oznacza to, że w pewnych sytuacjach będziemy
                                mogli do Ciebie zadzwonić aby poinformować Cię o
                                naszych niepublicznych promocjach, wydarzeniach
                                lub nowościach;
                              </span>
                            </span>
                          </span>
                        </p>
                      </li>
                      <li>
                        <p align="JUSTIFY">
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                jeśli zdecydujesz się na umożliwienie nam
                                przekazywanie niepublicznych informacji o
                                produktach, usługach i programach konsumenckich
                                &nbsp;
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                <strong>
                                  w dedykowanych do tego sekcjach w ramach
                                  portali społecznościowych
                                </strong>
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                , będziemy wyświetlać informacje dotyczące
                                naszych inicjatyw, wydarzeń lub niepublicznych
                                ofert w przeznaczonych do tego miejscach na
                                portalach / w usługach takich jak Facebook,
                                Instagram i innych oferujących podobne
                                funkcjonalności. Pamiętaj, że &nbsp;
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                <u>nie oznacza to</u>
                              </span>
                            </span>
                          </span>
                          <span style={{ color: "#262626" }}>
                            <span>
                              <span style={{ fontSize: "small" }}>
                                , że będziemy publikować jakiekolwiek treści w
                                Twoim imieniu (np. na Twojej osobistej "ścianie"
                                na Facebook'u). Nie będziemy również wysyłać do
                                Ciebie z własnej inicjatywy bezpośrednich
                                (prywatnych) wiadomości za pośrednictwem ww.
                                serwisów społecznościowych lub związanych z nimi
                                komunikatorów. &nbsp;
                              </span>
                            </span>
                          </span>
                        </p>
                      </li>
                    </ol>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podanie danych w tym przypadku nie jest obowiązkiem
                            prawnym, lecz Twoją dobrowolną, świadomą decyzją,
                            jednak bez ich znajomości nie będziemy w stanie
                            informować Cię o naszych niepublicznych
                            aktywnościach konsumenckich, ofertach, wydarzeniach
                            i innych inicjatywach.
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            W niektórych przypadkach, podanie tych danych wraz z
                            wyrażeniem odpowiedniej zgody jest warunkiem
                            otrzymania ofert specjalnych (o czym zostaniesz
                            wcześniej poinformowany/a np. w regulaminie danego
                            programu lub Kampanii).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              BAT i CHIC jako wspóladministratorzy
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Umówienie spotkania z naszym Ambasadorem
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Jeśli wyraziłeś/aś zainteresowanie wypróbowaniem
                            naszych produktów, poprosiliśmy Cię o podanie swoich
                            danych kontaktowych, abyśmy mogli umówić dla Ciebie
                            spotkanie z naszym Ambasadorem, który zaprezentuje
                            Ci i udostępni na okres testowych wybrany produkt z
                            naszej oferty. &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podanie danych w tym przypadku nie jest obowiązkiem
                            prawnym, lecz Twoją dobrowolną, świadomą decyzją,
                            jednak bez ich znajomości Ambasador nie będzie w
                            stanie skontaktować się z Tobą w celu ustalenia
                            dogodnego dla Ciebie miejsca i terminu spotkania.
                            &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              BAT i CHIC jako wspóladministratorzy
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w programie wsparcia posprzedażowego
                              &nbsp;
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Jeśli zdecydowałeś/aś się na przystąpienie do
                            naszego dedykowanego programu wsparcia
                            posprzedażowego, zostałeś/aś poproszony/a o podanie
                            swoich danych, które są niezbędne abyśmy mogli
                            kontaktować się z Tobą telefonicznie w&nbsp;celu
                            poznania Twojej opinii na temat naszych produktów
                            oraz satysfakcji związanej z ich użytkowaniem. W tym
                            celu będziemy mogli także wykorzystywać informacje
                            przekazane w ramach Twojej opinii, dla celów
                            udoskonalania naszych produktów i rozwoju naszej
                            oferty produktowej. &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podanie danych w tym przypadku nie jest obowiązkiem
                            prawnym, lecz Twoją dobrowolną, świadomą decyzją,
                            jednak bez ich znajomości nie będziemy w stanie
                            skontaktować się z Tobą w celu poznania Twojej
                            opinii na temat naszych produktów oraz satysfakcji z
                            ich użytkowania. W niektórych przypadkach,
                            niepodanie danych uniemożliwi Ci skorzystanie z
                            naszych &nbsp;
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            niepublicznych
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            &nbsp; ofert specjalnych (o czym zostaniesz
                            wcześniej poinformowany/a).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              BAT i CHIC jako wspóladministratorzy
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w niepublicznej akcji promocyjnej
                              wymagającej osobnej rejestracji
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Jeśli zdecydowałeś/aś się na udział w którejś z
                            naszych niepublicznych akcji promocyjnych,
                            zostałeś/aś poproszony o podanie pewnych informacji
                            na swój temat. Potrzebujemy ich w celu &nbsp;
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            przeprowadzenia oraz rozliczenia &nbsp;
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            niepublicznej
                          </span>
                        </span>
                      </span>
                      &nbsp;
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            akcji promocyjnej, w tym w celu realizacji ciążących
                            na nas obowiązków względem uczestników akcji, tym w
                            szczególności obsługi ich udziału w akcji, wydania
                            nagród oraz rozpatrywania ewentualnych reklamacji.
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podanie danych w tym przypadku nie jest obowiązkiem
                            prawnym, lecz Twoją dobrowolną, świadomą decyzją,
                            ale jest niezbędne do wzięcia udziału w &nbsp;
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            niepublicznej
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            &nbsp; akcji promocyjnej. Ich niepodanie spowoduje
                            brak możliwości zidentyfikowania Ciebie jako
                            uczestnika akcji, a co za tym idzie, także brak
                            możliwości przyznania Ci nagrody lub innych korzyści
                            związanych z udziałem w danej &nbsp;
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            niepublicznej
                          </span>
                        </span>
                      </span>
                      &nbsp;
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            akcji promocyjnej.
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Dalsze szczegółowe informacje dotyczące
                              konkretnych &nbsp;
                            </strong>
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            niepublicznych
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              akcji promocyjnych znajdziesz w ich regulaminach
                              publikowanych na naszych stronach internetowych.
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Informację znajdziesz w regulaminie danej{"{"}" "
                              {"}"}
                            </strong>
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>niepublicznej </strong>
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>akcji promocyjnej</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Realizacja Twojego zamówienia
                              <br />w sklepie sklep.glo.pl
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podczas składania zamówienia zostałeś/aś
                            poproszony/a o podanie informacji na swój temat, np.
                            imienia i nazwiska, adresu zamieszkania, adresu do
                            dostawy, informacji o dokonanej płatności za
                            zamówienie itp. Dane te są nam niezbędne, abyśmy
                            mogli zrealizować złożone przez Ciebie zamówienie.
                            Odmowa podania danych uniemożliwi nam przyjęcie
                            Twojego zamówienia, a w efekcie jego realizację.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>CHIC</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Proces rejestracji w Sklepie Glo</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podczas rejestracji konta w Sklepie Glo zostałeś/aś
                            poproszony/a o podanie informacji na swój temat, np.
                            imienia i nazwiska, adresu zamieszkania, adresu do
                            dostawy, informacji o dokonanej płatności za
                            zamówienie itp. &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podanie danych w tym przypadku nie jest obowiązkiem
                            prawnym, lecz Twoją dobrowolną, świadomą decyzją,
                            jednak bez ich znajomości nie będziemy w stanie
                            uruchomić Twojego konta użytkownika sklepu oraz
                            zidentyfikować Cię jako naszego zarejestrowanego
                            Klienta. &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podanie danych nie jest obowiązkiem prawnym, lecz
                            Twoją dobrowolną, świadomą decyzją, aczkolwiek ich
                            niepodanie uniemożliwi Ci zarejestrowanie konta w
                            Sklepie Glo.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>CHIC</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w naszych programach lojalnościowych
                              BAT
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Jeśli postanowiłeś/aś przystąpić do jednego z
                            naszych programów lojalnościowych, np. programu Glo
                            Lovers, podczas rejestracji zostałeś/aś poproszony/a
                            o podanie swoich danych. Są nam one niezbędne abyśmy
                            mogli włączyć Cię do grona uczestników programu. W
                            szczególności, obejmuje to uruchomienie i późniejszą
                            obsługę Twojego indywidualnego konta uczestnika.
                            Przykładowo, Twój adres e-mail pomoże nam
                            zidentyfikować Ciebie jako uczestnika programu, a
                            także będzie umożliwiał Ci logowanie na dedykowanej
                            stronie internetowej. Podanie danych nie jest
                            obowiązkiem prawnym, lecz Twoją dobrowolną, świadomą
                            decyzją, aczkolwiek ich niepodanie uniemożliwi Ci
                            dołączenie do programu lojalnościowego. &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>BAT</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Zarządzanie zwrotami i reklamacjami</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Jeśli postanowiłeś/aś zareklamować lub zwrócić
                            zakupiony u nas produkt, zostałeś/aś poproszony o
                            podanie w swoich danych osobowych takich jak m.in.
                            Twoje imię, nazwisko, adres zamieszkania lub numer
                            rachunku. Dane te są nam niezbędne abyśmy mogli
                            rozpatrzyć Twoją reklamację lub zwrot, co obejmuje w
                            szczególności kontakt z Tobą w celu poinformowania
                            Cię o naszej decyzji.
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Podanie danych w tym celu nie jest obowiązkiem
                            prawnym, lecz Twoją dobrowolną, świadomą decyzją,
                            aczkolwiek bez ich znajomości nie będziemy w stanie
                            rozpatrzyć Twojej reklamacji lub zwrotu.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="TOP" bgcolor="#ffffff">
                    <p>&nbsp;</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <a id="pkt3" />
              <br />
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      3. Na jakiej podstawie przetwarzamy Twoje dane osobowe?
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#474747" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Podstawy prawna przetwarzania Twoich danych osobowych
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span style={{ fontFamily: "Helvetica, serif" }}>
                  <span style={{ fontSize: "small" }}>
                    W poniższym zestawieniu wyjaśniamy podstawy prawne
                    uzasadniające przetwarzanie przez nas Twoich danych
                    osobowych. Zostały one przyporządkowane do każdego z celów
                    przetwarzania danych, które zostały omówione szerzej w
                    poprzednim punkcie.
                  </span>
                </span>
              </span>
            </p>
            <table border={1} cellSpacing={0} cellPadding={7}>
              <tbody>
                <tr>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span>
                        <span style={{ fontSize: "small" }}>
                          <strong>Cel przetwarzania</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Podstawa prawna przetwarzania Twoich danych
                              osobowych
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Prowadzenie niepublicznych działań marketingowych
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Twoja zgoda (art. 9 ust. 2 lit. a RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Umówienie spotkania z naszym Ambasadorem
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Twoja zgoda (art. 9 ust. 2 lit. a RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w programie wsparcia posprzedażowego
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Twoja zgoda (art. 9 ust. 2 lit. a RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td bgcolor="#ffffff">
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w niepublicznej akcji promocyjnej
                              wymagającej osobnej rejestracji
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td bgcolor="#ffffff">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Twoja zgoda (art. 9 ust. 2 lit. a RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezbędność przetwarzania danych w celu wykonania
                            obowiązków wynikających z przepisów prawa (np.
                            przepisów prawa podatkowego lub przepisów o
                            rachunkowości i&nbsp;sprawozdawczości finansowej)
                            (art. 6 ust. 1 lit. c RODO) &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Proces rejestracji w sklepie sklep.glo.pl
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Twoja zgoda (art. 9 ust. 2 lit. a RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Realizacja Twojego zamówienia
                              <br />w sklepie sklep.glo.pl
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezbędność przetwarzania danych w celu realizacji
                            umowy zawartej z Tobą poprzez złożenie zamówienia w
                            Sklepie Glo (art. 6 ust. 1 lit. b RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w naszych programach lojalnościowych
                              &nbsp;
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Twoja zgoda (art. 9 ust. 2 lit. a RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Zarządzanie reklamacjami i zwrotami</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Realizacja naszego obowiązku wynikającego z
                            przepisów prawa dotyczących w szczególności rękojmi
                            za wady rzeczy sprzedanej, gwarancji jakości oraz
                            prawa do odstąpienia od umowy zawartej na odległość
                            lub poza lokalem przedsiębiorstwa (art. 6 ust. 1
                            lit. c RODO)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p align="JUSTIFY">&nbsp;</p>
            <p>
              <a id="pkt4" />
              <a
                id="Jakie_prawa_związane_z_ochroną_danych"
                name="Jakie_prawa_zwi%C4%85zane_z_ochron%C4%85_danych"
              />
              &nbsp;
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      4. Jakie prawa związane z ochroną danych osobowych Ci
                      przysługują?
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#474747" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Wyjaśnienie Twoich praw wynikających z przepisów RODO
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#000000" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    W związku z tym, że zdecydowałeś/aś się na podanie nam
                    pewnych informacji na Twój temat i upoważniłeś/aś nas do ich
                    wykorzystania, przysługują Ci następujące prawa:
                  </span>
                </span>
              </span>
            </p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>prawo do wycofania zgody</strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp;- jeśli wyraziłeś/aś zgodę na przetwarzanie Twoich
                        danych osobowych w poszczególnym celu, możesz w każdym
                        czasie ją wycofać bez podawania przyczyny. Pamiętaj
                        jednak, że wycofanie zgody nie będzie wpływało na nasze
                        działania podjęte na jej podstawie jeszcze przede jej
                        wycofaniem &nbsp;
                      </span>
                    </span>
                  </span>
                </p>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        Więcej informacji na temat wycofania zgody znajdziesz w
                        tabeli &nbsp;
                        <a href="#Wsp%C3%B3%C5%82administrowanie">
                          <span
                            style={{
                              color: "#0000ff",
                              textDecoration: "underline",
                            }}
                          >
                            w pkt. 5 poniżej. &nbsp;
                          </span>
                        </a>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>
                          prawo do żądania dostępu do Twoich danych
                        </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp;- jesteś uprawniony/a do otrzymania od nas
                        potwierdzenia, czy Twoje dane osobowe są przez nas
                        przetwarzane, a jeśli ma to miejsce, możesz także
                        uzyskać do nich dostęp. W ramach dostępu do danych,
                        dostarczymy Ci kopię Twoich danych osobowych
                        pozostających w naszym posiadaniu. Za wszelkie kolejne
                        kopie, o które się do nasz zwrócisz, będziemy mogli
                        pobrać od Ciebie opłatę w rozsądnej wysokości
                        wynikającej z kosztów administracyjnych związanych z
                        przygotowaniem odpowiedniej informacji.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>prawo do sprostowania danych</strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp;- masz prawo do sprostowania lub poprawienia
                        przewarzanych przez nas osobowych, które Ciebie dotyczą,
                        a które są nieprawidłowe lub nieaktualne. Z
                        uwzględnieniem celów przetwarzania, masz również prawo
                        do żądania uzupełnienia niekompletnych danych osobowych,
                        w tym poprzez przedstawienie dodatkowego oświadczenia.
                      </span>
                    </span>
                  </span>
                </p>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        Więcej informacji na temat wycofania zgody znajdziesz w
                        tabeli &nbsp;
                        <a href="#Wsp%C3%B3%C5%82administrowanie">
                          <span
                            style={{
                              color: "#0000ff",
                              textDecoration: "underline",
                            }}
                          >
                            w pkt. 5 poniżej. &nbsp;
                          </span>
                        </a>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>
                          prawo do ograniczenia przetwarzania danych osobowych
                        </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp;- w razie zgłoszenia takiego żądania,
                        przetwarzanie określnych informacji na Twój temat
                        zostanie ograniczone tylko i wyłącznie do określonych
                        celów. Z uwagi na przechowywanie danych osobowych do
                        różnego rodzaju celów może wykazać istnienie ważnych
                        prawnie uzasadnionych podstaw do przetwarzania, w danej
                        sytuacji przeważających nad interesami, prawami i
                        wolnościami osoby, które dane dotyczą.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>prawo do przenoszenia danych </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp;- pod pewnymi warunkami przewidzianymi w
                        przepisach prawa przysługuje Ci prawo do otrzymania
                        Twoich danych w ustrukturyzowanym, powszechnie używanym
                        formacie nadającym się do odczytu maszynowego. Po
                        otrzymaniu danych, przysługuje Ci prawo do przekazania
                        ich innemu podmiotowi (administratorowi) bez żadnych
                        przeszkód z naszej strony. &nbsp;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>
                          prawo do usunięcia danych (tzw. "prawo do bycia
                          zapomnianym") &nbsp;
                        </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp;- masz prawo żądania, jeśli zachodzą okoliczności
                        przewidziane prawem, niezwłocznego usunięcia dotyczących
                        Ciebie danych osobowych, zaś my mamy w takiej sytuacji
                        obowiązek usunąć takie dane.
                      </span>
                    </span>
                  </span>
                </p>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        Więcej informacji na temat wycofania zgody znajdziesz w
                        tabeli &nbsp;
                        <a href="#Wsp%C3%B3%C5%82administrowanie">
                          <span
                            style={{
                              color: "#0000ff",
                              textDecoration: "underline",
                            }}
                          >
                            w pkt. 5 poniżej. &nbsp;
                          </span>
                        </a>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        <strong>prawo do sprzeciwu </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "#000000" }}>
                    <span>
                      <span style={{ fontSize: "small" }}>
                        &nbsp;- w pewnych okolicznościach masz prawo w dowolnym
                        momencie wnieść sprzeciw - z przyczyn związanych z Twoją
                        szczególną sytuacją - wobec przetwarzania dotyczących
                        Cię danych osobowych. W przypadku otrzymania takiego
                        sprzeciwu, będziemy zobowiązani do zaprzestania
                        przetwarzania Twoich danych osobowych w odpowiednim
                        zakresie;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              <span style={{ color: "#000000" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Aby skorzystać z powyższych praw, skontaktuj się z nami w
                      wybrany przez siebie sposób (dane kontaktowe podano w{"{"}
                      " "{"}"}
                    </strong>
                  </span>
                </span>
              </span>
              <a href="#pktpon">
                <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                  <strong>pkt. 1 III powyżej</strong>
                </span>
              </a>
              <span style={{ color: "#000000" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>).</strong>
                  </span>
                </span>
              </span>
            </p>
            <p>
              <a
                id="Współadministrowanie"
                name="Wsp%C3%B3%C5%82administrowanie"
              />
              &nbsp;
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      5. Co to znaczy, że BAT i CHIC są współadministratorami
                      Twoich danych osobowych?
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#474747" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Podstawowe informacje na temat uzgodnień BAT i CHIC w
                      sprawie współadministrowania danymi osobowymi
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    Oznacza to, że BAT oraz CHIC wspólnie ustalają tzw. "cele i
                    środki przetwarzania danych osobowych". W praktyce należy to
                    rozumieć w ten sposób, że BAT oraz CHIC wspólnie planują i
                    realizują pewne przedsięwzięcia, tworzą wspólną ofertę
                    produktową, jak również wspólnie prowadzą działania
                    marketingowe. Współpraca BAT oraz CHIC w powyższym zakresie
                    wynika z faktu, że należymy do jednej grupy przedsiębiorstw
                    i są wzajemnie powiązane kapitałowo. &nbsp;
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    Aby zapewnić zgodność tej współpracy z przepisami o ochronie
                    danych osobowych, BAT oraz CHIC zawarły umowę w sprawie
                    współadministrowania danymi osobowymi. Najistotniejsze
                    postanowienia tej umowy przedstawiamy poniżej:
                  </span>
                </span>
              </span>
            </p>
            <ol type="a">
              <ol type="a">
                <li>
                  <p align="JUSTIFY">
                    <span style={{ color: "#262626" }}>
                      <span>
                        <span style={{ fontSize: "small" }}>
                          BAT w zakresie danych objętych współadministrowaniem
                          będzie odpowiedzialny za umożliwienie Ci wykonywania
                          Twoich praw dotyczących danych osobowych. Pamiętaj
                          jednak, że niezależnie od powyższego ustalenia, w
                          sprawie Twoich praw możesz zwracać się również do
                          CHIC, wedle własnego uznania. W takim przypadku, Twoje
                          zgłoszenie zostanie przekazane do odpowiedniej
                          jednostki;/span&gt;
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="JUSTIFY">
                    <span style={{ color: "#262626" }}>
                      <span>
                        <span style={{ fontSize: "small" }}>
                          ustanowiono wspólny punkt kontaktowy w sprawach
                          związanych z ochrona danych osobowych naszych
                          Klientów; aby się z nami skontaktować w sprawie
                          przetwarzania Twoich danych osobowych możesz:
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="JUSTIFY">
                    <span style={{ color: "#262626" }}>
                      <span>
                        <span style={{ fontSize: "small" }}>
                          -napisać na adres poczty elektronicznej
                          daneosobowe@bat.com.pl;
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </ol>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    -napisać na adres British American Tobacco Polska Trading
                    sp. z o.o., ul. Krakowiaków 48, 02-255 Warszawa, z dopiskiem
                    „Dane Osobowe” albo na adres: CHIC spółka z ograniczoną
                    odpowiedzialnością sp. k., ul. Aleja Wojska Polskiego 23C,
                    63-500 Ostrzeszów &nbsp;
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#262626" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    Realizacja wspólnych celów wiąże się z wykorzystaniem przez
                    BAT i CHIC wspólnych środków przetwarzania. W praktyce
                    należy to rozumieć w ten sposób, że BAT oraz CHIC korzystają
                    ze wspólnego systemu informatycznego do przetwarzania
                    współadministrowanych danych osobowych, w związku z
                    powyższym przedstawiamy poniżej szczegółowe informacje
                    dotyczące realizacji poszczególnych praw, o których mowa
                    &nbsp;
                    <a href="#Jakie_prawa_zwi%C4%85zane_z_ochron%C4%85_danych">
                      <span
                        style={{
                          color: "#0000ff",
                          textDecoration: "underline",
                        }}
                      >
                        w pkt 4 powyżej.
                      </span>
                    </a>
                  </span>
                </span>
              </span>
            </p>
            <table border={1} cellSpacing={0} cellPadding={7}>
              <tbody>
                <tr>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span>
                        <span style={{ fontSize: "small" }}>
                          <strong>
                            Prawo związane z przetwarzaniem danych osobowych
                          </strong>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Szczegółowe informacje na temat realizacji prawa w
                              ramach współadministrowania przez BAT i CHIC
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Prawo do wycofania zgody</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Pamiętaj, że dla każdego ze wskazanych{"{"}" "
                              {"}"}
                              <a href="#pkt3">
                                <span
                                  style={{
                                    color: "#0000ff",
                                    textDecoration: "underline",
                                  }}
                                >
                                  w pkt 3 powyżej
                                </span>
                              </a>
                              &nbsp; celów przetwarzania, których podstawą
                              przetwarzania jest udzielona przez Ciebie zgoda,
                              możesz wycofać każdą z udzielonych zgód
                              niezależnie. Dla zapewnienia przejrzystości,
                              przedstawiamy poniżej szczegółowe informacje:
                            </strong>
                            &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                    <ul>
                      <li style={{ fontSize: "small" }}>
                        <p>
                          Wycofanie przez Ciebie zgody na przetwarzanie Twoich
                          danych osobowych w celach prowadzenia niepublicznych
                          działań marketingowych oznacza, ze nie będziesz
                          otrzymywał od nas więcej tego typu informacji.
                        </p>
                        <p>
                          Pamiętaj, że możesz również zdecydować, czy nie chcesz
                          otrzymywać takich informacji za pośrednictwem
                          wszystkich lub tylko niektórych dostępnych sposobów
                          (kanałów) komunikacji.
                        </p>
                        <p>
                          Co istotne, wycofanie zgody na przetwarzanie Twoich
                          danych osobowych w celach prowadzenia niepublicznych
                          działań marketingowych, nie jest jednoznaczne z
                          usunięciem Twojego konta w Sklepie Glo, Programie ESC
                          lub innym programie lojalnościowym BAT.
                        </p>
                        <p>
                          W niektórych przypadkach wycofanie niniejszej zgody
                          będzie natomiast wpływało na utratę przyznanych
                          korzyści oraz uprawnień w ramach poszczególnych
                          programów. Aby wycofać zgodę możesz skontaktować się z
                          nami w wybrany przez siebie sposób (dane kontaktowe
                          podano w &nbsp;
                          <a href="#pktpon">
                            <span
                              style={{
                                color: "#0000ff",
                                textDecoration: "underline",
                              }}
                            >
                              pkt. 1 III powyżej
                            </span>
                          </a>
                          ).
                        </p>
                      </li>
                      <li style={{ fontSize: "small" }}>
                        <p>
                          Wycofanie przez Ciebie zgody na przetwarzanie Twoich
                          danych osobowych w celu uczestnictwa w programie
                          lojalnościowym ESC oznacza usunięcie Twojego konta w
                          programie ESC oraz utratę przyznanych punktów
                          lojalnościowych lub innych praw nabytych zgodnie z
                          zasadami określonymi w regulaminie programu ESC.
                        </p>
                        <p>
                          Aby wycofać zgodę możesz: skontaktować się z nami w
                          wybrany przez siebie sposób (dane kontaktowe podano w
                          &nbsp;
                          <a href="#pktpon">
                            <span
                              style={{
                                color: "#0000ff",
                                textDecoration: "underline",
                              }}
                            >
                              pkt. 1 III powyżej)
                            </span>
                          </a>
                          ), usuwając samodzielnie swoje konto w programie ESC w
                          dedykowanym do tego panelu użytkownika, jeżeli taka
                          funkcjonalność została przewidziana dla poszczególnego
                          programu, a także pisząc na dedykowany adres programu
                          ESC &nbsp;
                          <a href="mailto:esmokingclub@esmokingworld.com">
                            <span
                              style={{
                                color: "#0000ff",
                                textDecoration: "underline",
                              }}
                            >
                              esmokingclub@esmokingworld.com.
                            </span>
                          </a>
                        </p>
                      </li>
                      <li style={{ fontSize: "small" }}>
                        <p>
                          Wycofanie przez Ciebie zgody na przetwarzanie Twoich
                          danych osobowych w celu związanym z utworzeniem i
                          obsługą Twojego konta w Sklepie Glo oznacza usunięcie
                          Twojego konta w sklepie Glo, w tym między innymi
                          utratę dostępu do Twoich dotychczasowych zamówień.
                        </p>
                        <p>
                          Aby wycofać zgodę możesz skontaktować się z nami w
                          wybrany przez siebie sposób (dane kontaktowe podano w
                          &nbsp;
                          <a href="#pktpon">
                            <span
                              style={{
                                color: "#0000ff",
                                textDecoration: "underline",
                              }}
                            >
                              pkt. 1 III powyżej)
                            </span>
                          </a>
                          ) lub usuwając samodzielnie swoje konto w sklepie Glo
                          w dedykowanym do tego panelu użytkownika.
                        </p>
                      </li>
                      <li style={{ fontSize: "small" }}>
                        <p>
                          Wycofanie przez Ciebie zgody na przetwarzanie Twoich
                          danych osobowych w celu uczestnictwa w programie
                          lojalnościowym BAT oznacza, usunięcie Twojego konta w
                          danym programie.
                        </p>
                        <p>
                          Aby wycofać zgodę możesz skontaktować się z nami w
                          wybrany przez siebie sposób (dane kontaktowe podano w
                          &nbsp;
                          <a href="#pktpon">
                            <span
                              style={{
                                color: "#0000ff",
                                textDecoration: "underline",
                              }}
                            >
                              pkt. 1 III powyżej)
                            </span>
                          </a>
                          ) lub usunąć niezależnie swoje konto w danym programie
                          lojalnościowym BAT w dedykowanym do tego panelu
                          użytkownika programu, jeżeli taka funkcjonalność
                          została przewidziana dla poszczególnego programu.
                        </p>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Prawo do sprostowania danych</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Pamiętaj, że realizacja prawa będzie skuteczna
                              równocześnie wobec każdego z Administratorów.
                            </strong>
                            &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Oznacza to, że jeżeli posiadasz konto w sklepie Glo
                            oraz/lub jesteś członkiem programu ESC oraz/lub
                            jesteś członkiem programu lojalnościowego BAT, to
                            zmiany Twoich danych osobowych (adres email, nr
                            telefonu) będą miały zastosowanie we wszystkich
                            kontach na stronach internetowych BAT i CHIC,
                            których jesteś zarejestrowanym użytkownikiem oraz we
                            wszystkich programach BAT i CHIC, których jesteś
                            uczestnikiem. &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Prawo do usunięcia danych </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Pamiętaj, że skorzystanie z prawa do bycia
                              zapomnianym jest równoznaczne z usunięciem
                              wszystkich Twoich danych osobowych przetwarzanych
                              przez BAT i CHIC.
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Oznacza to między innymi:
                          </span>
                        </span>
                      </span>
                    </p>
                    <ol style={{ fontSize: "small" }}>
                      <li>usunięcie Twojego konta w Sklepie Glo oraz;</li>
                      <li>
                        usunięcie Twojego konta z programów lojalnościowych BAT
                        oraz utrata praw nabytych zgodnie z zasadami określonymi
                        w regulaminie programu;
                      </li>
                      <li>
                        usunięcie Twojego konta z programu lojalnościowego ESC
                        oraz utratę przyznanych punktów lojalnościowych lub
                        innych
                      </li>
                    </ol>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Aby skorzystać z powyższego prawa, możesz
                            skontaktować się z nami w wybrany przez siebie
                            sposób (dane kontaktowe podano w &nbsp;
                            <a href="#pktpon">
                              <span
                                style={{
                                  color: "#0000ff",
                                  textDecoration: "underline",
                                }}
                              >
                                pkt. 1 III powyżej)
                              </span>
                            </a>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      6. Jak długo będziemy przetwarzać Twoje dane?
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#595959" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Przewidywane terminy zaprzestania przetwarzania danych lub
                      kryteria ich określenia
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span>
                <span style={{ fontSize: "small" }}>
                  W tym punkcie przedstawiamy informacje na temat przewidywanych
                  terminów zaprzestania przetwarzania Twoich danych osobowych.
                  Co do zasady, posiadane przez nas informacje na Twój temat są
                  usuwane po stwierdzeniu ich nieprzydatności dla celów, w
                  związku z którymi zostały od Ciebie pozyskane. Pamiętaj
                  jednak, że czasami ten sam zestaw danych może być przez nas
                  przetwarzany dla różnych celów. Szczegółowe informacje na ten
                  temat znajdziesz w poniższym zestawieniu.
                </span>
              </span>
            </p>
            <table border={1} cellSpacing={0} cellPadding={7}>
              <tbody>
                <tr>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span>
                        <span style={{ fontSize: "small" }}>
                          <strong>Cel przetwarzania</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td bgcolor="#bfbfbf">
                    <p align="CENTER">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Przewidywany termin zaprzestania przetwarzania
                              Twoich danych
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Prowadzenie niepublicznych działań marketingowych
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezwłocznie po wycofaniu przez Ciebie zgody lub po
                            5-letnim okresie braku aktywności z Twojej strony
                            (braku logowania na naszych stronach WWW, brak
                            odnotowanego udziału w naszych konkursach, brak
                            reakcji na nasze wiadomości)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Umówienie spotkania z naszym Ambasadorem
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Po upływie 2 miesięcy po odbyciu spotkania z naszym
                            Ambasadorem, chyba że wcześniej wycofasz swoją zgodę
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w programie wsparcia posprzedażowego
                              &nbsp;
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezwłocznie po wycofaniu przez Ciebie zgody lub po
                            upływie 2 miesięcy po zakończeniu programu
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w niepublicznej akcji promocyjnej
                              wymagającej osobnej rejestracji
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezwłocznie po wycofaniu przez Ciebie zgody lub po
                            upływie 2 miesięcy po zakończeniu niepublicznej
                            akcji promocyjnej
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            W zakresie danych niezbędnych do celów podatkowych i
                            rozliczeniowych - po upływie 5 lat od zakończenia
                            niepublicznej akcji promocyjnej
                          </span>
                        </span>
                      </span>
                    </p>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Informujemy, że w przypadku poszczególnych
                              niepublicznych akcji promocyjnych, ich założenia
                              mogą wymuszać przyjęcie innych terminów usunięcia
                              danych. Szczegóły znajdziesz w regulaminach
                              konkretnych niepublicznych akcji publikowanych na
                              naszych stronach internetowych
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Realizacja Twojego zamówienia
                              <br />w sklepie sklep.glo.pl
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Po upływie 2 miesięcy od realizacji Twojego
                            zamówienia &nbsp;
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Proces rejestracji w sklepie sklep.glo.pl
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezwłocznie po wycofaniu przez Ciebie zgody lub po
                            5-letnim okresie braku aktywności z Twojej strony
                            (braku logowania na naszych stronach WWW, brak
                            odnotowanego udziału w naszych konkursach, brak
                            reakcji na nasze wiadomości)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w naszych programach lojalnościowych
                              BAT &nbsp;
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezwłocznie po wycofaniu przez Ciebie zgody
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>
                              Uczestnictwo w programach lojalnościowych CHIC
                              (Program ESC)
                            </strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Do czasu wycofania przez Ciebie zgody na
                            przetwarzanie danych osobowych albo do zakończenia
                            trwania Programu ESC lub wygaśnięcia Twojego
                            uczestnictwa w Programie ESC zgodnie z zasadami
                            określonymi w regulaminie określającym zasady
                            udziału w Programie ESC
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            <strong>Zarządzanie reklamacjami i zwrotami</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p align="JUSTIFY">
                      <span style={{ color: "#262626" }}>
                        <span>
                          <span style={{ fontSize: "small" }}>
                            Niezwłocznie po zakończeniu procesu rozpatrywania
                            reklamacji lub zwrotu
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p align="JUSTIFY">&nbsp;</p>
            <p>
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>7. Komu będziemy przekazywać Twoje dane?</strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#595959" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Przewidywani odbiorcy lub kategorie odbiorców danych
                      osobowych
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span>
                <span style={{ fontSize: "small" }}>
                  W zależności od sytuacji oraz celów, w związku z którymi
                  przetwarzamy Twoje dane osobowe, będziemy mogli je przekazywać
                  następującym grupom odbiorców:
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p align="JUSTIFY">
                  <span>
                    <span style={{ fontSize: "small" }}>
                      innym spółkom z grupy kapitałowej British American Tobacco
                      ("
                    </span>
                  </span>
                  <span>
                    <span style={{ fontSize: "small" }}>
                      <strong>grupa BAT</strong>
                    </span>
                  </span>
                  <span>
                    <span style={{ fontSize: "small" }}>
                      ") w celach związanych z zarządzaniem wspólnymi systemami
                      informatycznymi na podstawie umowy między spółkami z grupy
                      BAT dotyczącej przekazywania danych osobowych, zawartej
                      zgodnie ze standardowymi klauzulami ochrony danych
                      przyjętymi przez Komisję Europejską;
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span>
                    <span style={{ fontSize: "small" }}>
                      &nbsp; franczyzobiorcom sieci eSmoking World;
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span>
                    <span style={{ fontSize: "small" }}>
                      &nbsp; naszym dostawcom, którym zlecimy usługi związane z
                      przetwarzaniem danych osobowych, np. dostawcom usług IT,
                      agencjom organizującym w naszym imieniu konkursy lub inne
                      wydarzenia i inicjatywy. Takie podmioty przetwarzają dane
                      na podstawie umowy z nami i tylko zgodnie z naszymi
                      poleceniami;
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  <span>
                    <span style={{ fontSize: "small" }}>
                      jeśli wyraziłeś/aś zgodę na przekazywanie komunikatów
                      marketingowych za pośrednictwem serwisów
                      społecznościowych, będziemy przekazywać Twoje dane osobowe
                      (adresy e-mail w zaszyfrowanej postaci) operatorom takich
                      serwisów. Jak wyjaśniono już w &nbsp;
                      <a href="#pkt">
                        <span
                          style={{
                            color: "#0000ff",
                            textDecoration: "underline",
                          }}
                        >
                          pkt. 2 powyżej,
                        </span>
                      </a>
                      &nbsp; przekazanie danych ma na celu zidentyfikowanie
                      Ciebie jako naszego zarejestrowanego Klienta,
                      zainteresowanego otrzymywaniem informacji na temat naszych
                      najnowszych ofert, nowości produktowych, konkursów i
                      innych wydarzeń lub inicjatyw. Zidentyfikowanie Ciebie
                      jako naszego Klienta jest konieczne, ponieważ ze względu
                      na ograniczenia prawne, nie możemy kierować informacji na
                      temat naszych produktów do osób poniżej 18 roku życia, nie
                      będących konsumentami wyrobów tytoniowych, w tym
                      nowatorskich wyrobów tytoniowych lub użytkownikami
                      papierosów elektronicznych.
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      8. Przekazywanie danych poza Europejski Obszar Gospodarczy
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#595959" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Przewidywani obiorcy Twoich danych poza Europejskim
                      Obszarem Gospodarczym oraz zasady ich przekazywania
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#000000" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    Chcemy zapewnić bezpieczeństwo Twoich danych osobowych.
                    Zastrzegamy sobie możliwość, aby w uzasadnionych sytuacjach
                    przekazywać Twoje dane osobowe do odbiorców zlokalizowanych
                    poza Europejskim Obszarem Gospodarczym oraz Unią Europejską.
                    Dotyczy to w szczególności podmiotów należących do grupy
                    British American Tobacco. Gwarantujemy, że takie przekazanie
                    będzie w każdym przypadku zgodne z obowiązującymi przepisami
                    o ochronie danych osobowych i zapewniony zostanie odpowiedni
                    stopień ochrony Twoich danych. Przekazanie odbywać się
                    będzie na podstawie umowy opartej na tzw. "standardowych
                    klauzulach ochrony danych", w brzmieniu zatwierdzonym przez
                    Komisję Europejską. &nbsp;
                  </span>
                </span>
              </span>
            </p>
            <p>
              <span style={{ color: "#0070c0" }}>
                <span>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      9. Gdzie możesz zgłosić zastrzeżenia dotyczące
                      postępowania z Twoimi danymi?
                    </strong>
                  </span>
                </span>
              </span>
              <br />
              <span style={{ color: "#595959" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    <strong>
                      Możliwość złożenia skargi do właściwego organu państwowego
                      do spraw ochrony danych osobowych
                    </strong>
                  </span>
                </span>
              </span>
            </p>
            <p align="JUSTIFY">
              <span style={{ color: "#000000" }}>
                <span>
                  <span style={{ fontSize: "small" }}>
                    W przypadku wątpliwości lub uznania, że Twoje dane osobowe
                    są przez nas przetwarzane niezgodnie z obowiązującymi
                    przepisami prawa, przysługuje Ci prawo wniesienia skargi do
                    organu nadzorczego zajmującego się ochroną danych osobowych.
                    W Polsce takim organem jest Prezes Urzędu Ochrony Danych
                    Osobowych z siedzibą przy ul. Stawki 2 w Warszawie (kod
                    pocztowy: 00-193). &nbsp;
                  </span>
                </span>
              </span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicyPage;
