import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import EE from '../../langs/ee.json';
import RU from '../../langs/ru.json';
import { useParams } from "react-router-dom";
import mapSvg from "../../assets/img/map-icon-white.svg";
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import setEvent from '../../hook/setEvent';

import constants from '../../constants';

// Mapbox
import stores from './stores';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';

const MapPage = () => {
  const { lang } = useParams();
  const data = lang === "ee" ? EE : RU;
  const lists = [...stores.features];
  const [mapInstance, setMapInstance] = useState(null);
  const [mapDirectionInstance, setMapDirectionInstance] = useState(null);
  const [searchField, setSearchField] = useState('');

  stores.features.forEach(function (store, i) {
    store.properties.id = i;
  });

  const flyToStore = (currentFeature, map) => {
    map.flyTo({
      center: currentFeature.geometry.coordinates,
      zoom: 15
    });
  }

  const createPopUp = (currentFeature, map) => {
    const popUps = document.getElementsByClassName('mapboxgl-popup');
    if (popUps[0]) popUps[0].remove();

    const popup = new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat(currentFeature.geometry.coordinates)
      .setHTML(`<h3>${currentFeature.properties.store_name}</h3><h4>${currentFeature.properties.address}, ${currentFeature.properties.city}</h4>`)
      .addTo(map);

    popup.on('close', () => {
      map.flyTo({
        center: [26.0136, 58.7953],
        zoom: 6
      });

      setSearchField('');
      const activeItem = document.getElementsByClassName('active');
      if (activeItem[0]) {
        activeItem[0].classList.remove('active');
      }
    });
  }

  const handleActive = (activeStore) => {
    const activeItem = document.getElementsByClassName('active');
    if (activeItem[0]) {
      activeItem[0].classList.remove('active');
    }
    const listing = document.getElementById(
      `listing-${activeStore.properties.id}`
    );
    listing.classList.add('active');
  }

  useEffect(() => {
    setEvent({
      'UserId': null,
      'PageType': 'mappage',
      'SystemType': null,
      'Country': lang,
      'LoggedInStatus': 'logged out',
      'SiteSection': 'Map',
      'ServerSideTampstamp': Date.now()
    });

    mapboxgl.accessToken = constants.mapboxToken;
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/gastonluisblanco/cl8nexoti000k14mmil7qebm4',
      center: [26.0136, 58.7953],
      zoom: 6,
      cooperativeGestures: true
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    const mapDirect = new MapboxDirections({
      accessToken: constants.mapboxToken,
      unit: 'metric',
      language: 'et-EE',
      flyTo: true
    }).on('origin', ({ feature }) => {
      // setCurrOrigin([...feature.geometry.coordinates]);
    });

    map.addControl(mapDirect, "top-left");

    map.on('load', () => {
      map.addLayer({
        id: 'locations',
        type: 'circle',
        source: {
          type: 'geojson',
          data: stores
        }
      });

      map.on('click', (event) => {
        const features = map.queryRenderedFeatures(event.point, {
          layers: ['locations']
        });

        if (!features.length) return;

        const clickedPoint = features[0];

        flyToStore(clickedPoint, map);

        createPopUp(clickedPoint, map);

        const listing = document.getElementById(
          `listing-${clickedPoint.properties.id}`
        );
        if (listing === null) {
          setSearchField(clickedPoint.properties.store_name.split(',')[0].toLowerCase());
        }

        setTimeout(() => {
          handleActive(clickedPoint);
        }, 0)
      });
    });

    setMapInstance(map);
    setMapDirectionInstance(mapDirect);
    setupDirectionsTranslation();
  }, [lang]);

  const handleChange = ({ target }) => setSearchField(target.value);

  const handleStore = ({ target }) => {
    for (const feature of stores.features) {
      if (target.id === `link-${feature.properties.id}`) {
        setEvent({
          'eventName': 'StoreLocatorSearch',
          'searchedstore': feature.properties.store_name,
          'getDirections': 'yes',
          'distance': '1.2km'
        });

        flyToStore(feature, mapInstance);
        createPopUp(feature, mapInstance);
      }
    }
    const activeItem = document.getElementsByClassName('active');
    if (activeItem[0]) {
      activeItem[0].classList.remove('active');
    }
    target.parentNode.classList.add('active');
  }

  const setCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { longitude, latitude } }) => {
          // setCurrOrigin([longitude, latitude]);
          mapDirectionInstance.setOrigin([longitude, latitude]);
        }
      );
    }
  }

  const setupDirectionsTranslation = () => {
    setTimeout(() => {
      const originInput = document.querySelector('#mapbox-directions-origin-input > div > input[type=text]');
      originInput.setAttribute('placeholder', 'Valige alguspunkt');
      const destinationInput = document.querySelector('#mapbox-directions-destination-input > div > input[type=text]');
      destinationInput.setAttribute('placeholder', 'Valige sihtpunkt');
      const directions = document.querySelectorAll('.mapbox-directions-profile > label');
      const traffic = directions[0];
      const driving = directions[1];
      const walking = directions[2];
      const cycling = directions[3];
      traffic.innerHTML = 'Ühistransport';
      driving.innerHTML = 'Sõitmine';
      walking.innerHTML = 'Kõndimine';
      cycling.innerHTML = 'Rattasõit';
    }, 0);
  }

  const filteredStores = lists.filter(store => {
    let city = store.properties.city.toLowerCase();
    city = city.replace(',', '');
    return city.includes(searchField.toLowerCase());
  });

  if (filteredStores.length === 0) {
    setEvent({
      'eventName': 'StoreLocatorSearch',
      'searchedstore': 'no store found'
    });
  } else {
    setEvent({
      'eventName': 'StoreLocatorSearch',
      'searchedstore': searchField.toLowerCase(),
      'storeSearchResults': filteredStores.length
    });
  }

  return (
    <Layout footerType="black" langData={data}>
      <section className="map-page">
        <div className="container--center">
          <div className="columns">
            <div className="left-column">
              <div className="sidebar">
                <h1>{data.map.title}</h1>
                <div className="searchbar">
                  <input className="search" onChange={handleChange} type="text" name="search" placeholder={data.map.search} value={searchField} />
                </div>
                <div className='currentLocation'>
                  <span onClick={setCurrentLocation}><img src={mapSvg} alt="Current Location" />{data.map.currentLocation}</span>
                </div>
                <div id='listings' className='listings'>
                  {filteredStores.map(store =>
                    <div id={`listing-${store.properties.id}`} key={store.properties.id} className='item' data-store={store.properties.store_name}>
                      <a href="#" onClick={handleStore} className="title" id={`link-${store.properties.id}`}>{store.properties.store_name}</a>
                      <div className="address-detail">{`${store.properties.address}, ${store.properties.city}`}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="right-column">
              <div id="map"></div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MapPage;
