import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useParams } from "react-router-dom";
import axiosClient from "./utils/axios-client";
import { useEffect, useState } from "react";
import VeloPage from "./pages/VeloPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import FaqPage from "./pages/FaqPage";
import MapPage from "./pages/MapPage";
import Forbidden from "./pages/Forbidden";

function App() {

  const [status, setStatus] = useState(200);

  useEffect(() => {
    axiosClient
    .get("/landing")
    .then((res) => {
      setStatus(res.status);
    })
    .catch((err) => {
      setStatus(err.request.status);
    });

  }, []);

  const ProtectedRoute = ({ status }) => {
  const { lang } = useParams();
  
  switch (status) {
      case 403:
        return <Navigate to={`/${lang}/forbidden`} replace />;
        break;

      default:
        return <Outlet />;
        break;
    }
  };

  return (
    <>
      <Router basename="/ee/ee">
        <Routes>
          <Route path="/" element={<Navigate to="/ee" replace />} />
          <Route element={<ProtectedRoute status={status} />}>
            <Route path="/:lang" element={<VeloPage />} />
            <Route path="/:lang/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/:lang/faq" element={<FaqPage />} />
            <Route path="/:lang/where-to-buy" element={<MapPage />} />
          </Route>
          <Route path="/:lang/forbidden" element={<Forbidden />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
