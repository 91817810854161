import { useState } from "react";
import './faqQuestion.scss';

const FaqQuestion = ({ question, answer }) => {
  const [active, setActive] = useState(false)

  return (
    <div className={active ? "faq-question active" : "faq-question"}>
      <h2 className="question" onClick={() => { setActive(!active) }}>{question}</h2>
      <div className="answer" dangerouslySetInnerHTML={{ __html: answer }}></div>
    </div>
  );
};

export default FaqQuestion;
