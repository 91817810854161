import {useState} from "react";
import "./header.scss";
import logoSvg from "../../assets/logo/velo-logo.svg";
import mapSvg from "../../assets/img/map-icon-white.svg";
import chevron from "../../assets/img/chevron-down.svg";
import menu from "../../assets/img/menu.svg";
import close from "../../assets/img/close.svg";
import { Link, useParams } from "react-router-dom";

const Header = ({langData}) => {
  //const { language, changeLang } = useModalContext();
  const [langDropdown, setLangDropdown] = useState(false);
  const {lang} = useParams();
  const [navActive, setNavActive] = useState(false);

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <div>
      <header className="header">
        <div className="container">
          <div className="logo" onClick={() => window.scrollTo(0, 0)}>
            <Link to={`/${lang}`} >
              <img src={logoSvg} alt="velo logo" />
            </Link>
          </div>
        </div>
        <div className="nav-button" onClick={()=>{setNavActive(!navActive)}}>
          { navActive ? 
            <>
              <img src={close} />
              <span>{ langData.menu.close }</span>
            </>
            :
            <>
              <img src={menu} />
              <span>{ langData.menu.menu }</span>
            </>
          }
        </div>
        <nav className={navActive ? "nav active": "nav"}>
          <a href={`/ee/ee/${lang}/#what-is-velo`} onClick={()=>{setNavActive(false)}}>{ langData.menu.what_is_velo }</a>
          <a href={`/ee/ee/${lang}/#velo-products`} onClick={()=>{setNavActive(false)}}>{ langData.menu.velo_products }</a>
          <a href={`/ee/ee/${lang}/#how-to-use`} onClick={()=>{setNavActive(false)}}>{ langData.menu.how_to_use }</a>
          <Link to={`/${lang}/faq`} onClick={()=>{setNavActive(false)}}>{ langData.menu.faq }</Link>
        </nav>
        <div className="map-link">
          <Link to={`/${lang}/where-to-buy`}>
            <img src={mapSvg} alt="Where to buy" />
            <span>{langData.menu.where_to_buy}</span>
          </Link>
        </div>
        <div className={langDropdown ? "dropdown lang-dropdown active" : "dropdown lang-dropdown"}>
          <div className="selected" onClick={() => { setLangDropdown(!langDropdown) }}>
            <span>{lang}</span>
            <img src={chevron} />
          </div>
          <div className="dropdown-items">
            <Link to="/ee" onClick={() => { setLangDropdown(!langDropdown) }}>EE</Link>
            <Link to="/ru" onClick={() => { setLangDropdown(!langDropdown) }}>RU</Link>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
