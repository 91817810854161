import Cookie from 'js-cookie';

const SetCookie = (cookiename,usrin)=>{
    Cookie.set(cookiename, usrin,{
        expires:24, // 1 day
        secure:true,
        sameSitre:'strict',
        path:'/'
    });
}

export default SetCookie;