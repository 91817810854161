import React from "react";
import Layout from "../components/Layout/Layout";
import EE from '../langs/ee.json';
import RU from '../langs/ru.json';
import { useParams } from "react-router-dom";
import FaqQuestion from "../components/FaqQuestion/FaqQuestion";

const FaqPage = () => {
  const {lang} = useParams();
  const data = lang === "ee" ? EE : RU;

  return (
    <Layout footerType="black" langData={data}>
      <section className="faq-page">
        <div className="container--center">
          <h1>{ data.faq.title }</h1>
          <p className="faq-description">{ data.faq.desc }</p>
          <div className="faq-questions">
            { data.faq.questions.map((item, key)=>{
              return <FaqQuestion key={'question'+key} answer={item.answer} question={item.question} />
            }) }
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FaqPage;
