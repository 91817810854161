import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import styles from "../Slider/slider.scss";

import ArrowLeft from "./img/arrow-left.png";
import ArrowRight from "./img/arrow-right.png";

const Slider = ({ mobileImg1, desktopImg1, mobileImg2, desktopImg2, mobileImg3, desktopImg3 }) => {
  const swiper = useSwiper();

  return (
    <div className="slider-container">
      <div className="swiper-button-prev">
        <img src={ArrowLeft} alt="Strzałka w lewo - poprzedni slide" />
      </div>
      <div className="swiper-button-next">
        <img src={ArrowRight} alt="Strzałka w prawo - następny slide" />
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => {}}
        onSwiper={() => {}}
        modules={[Navigation]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        <SwiperSlide>
          <img
            className="velo-page__image"
            src={mobileImg3}
            alt="SASZETKI NIKOTYNOWE."
          />
          <img
            className="velo-page__image--destkop"
            src={desktopImg3}
            alt="SASZETKI NIKOTYNOWE."
          />
        </SwiperSlide>
        {/*<SwiperSlide>
          <img
            className="velo-page__image"
            src={mobileImg1}
            alt="SASZETKI NIKOTYNOWE."
          />
          <img
            className="velo-page__image--destkop"
            src={desktopImg1}
            alt="SASZETKI NIKOTYNOWE."
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="velo-page__image"
            src={mobileImg2}
            alt="SASZETKI NIKOTYNOWE."
          />
          <img
            className="velo-page__image--destkop"
            src={desktopImg2}
            alt="SASZETKI NIKOTYNOWE."
          />
      </SwiperSlide>*/}
      </Swiper>
    </div>
  );
};

export default Slider;
