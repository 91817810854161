import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import Layout from "../components/Layout/Layout";
import setEvent from "../hook/setEvent";
import EE from "../langs/ee.json";
import RU from "../langs/ru.json";

const Forbidden = () => {
    const {lang} = useParams();
    const data = lang === "ee" ? EE : RU;

    useEffect(() => {
        setEvent({
            'UserId': null,
            'PageType': 'forbidden',
            'SystemType': null,
            'Country': lang,
            'LoggedInStatus': 'logged out',
            'SiteSection': 'forbidden',
            'ServerSideTampstamp': Date.now()
        });
    }, [])

    return (
        <Layout footerType="black" langData={data}>
            <section className="faq-page">
                <h1>Forbidden</h1>
            </section>
        </Layout>
    );
};

export default Forbidden;
